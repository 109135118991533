define("scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "manage-resources/claims-form/claim-value", [], ["excludeGroup", true, "claim", ["subexpr", "@mut", [["get", "permission.permissionValue.permissionTarget", ["loc", [null, [5, 73], [5, 116]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-permissionWithClaimForm"], ["loc", [null, [5, 6], [5, 156]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 17,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "select-2", [], ["value", ["subexpr", "@mut", [["get", "permission.permissionValue.permissionTarget", ["loc", [null, [8, 14], [8, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "enabled", ["subexpr", "not", [["get", "disabled", ["loc", [null, [9, 21], [9, 29]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 16], [9, 30]]], 0, 0], "optionIdPath", "id", "optionLabelPath", "name", "didSelect", ["subexpr", "action", ["didSelectPermissionTarget", ["get", "permission", ["loc", [null, [12, 54], [12, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 18], [12, 65]]], 0, 0], "query", "searchUserGroups", "placeholder", ["subexpr", "t", ["components.manage_resources.permissions_form.request_target_placeholder"], [], ["loc", [null, [14, 20], [14, 97]]], 0, 0], "class", "manageResources-itemForm-form-input at-permissionsForm"], ["loc", [null, [7, 6], [16, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 6
            },
            "end": {
              "line": 22,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "class", "button button--white at-buttonGroup at-permissionsForm at-claimToggler");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["toggleClaimModeForPermissionTarget", ["get", "permission", ["loc", [null, [21, 62], [21, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 16], [21, 74]]], 0, 0], ["inline", "inline-svg", ["icons/user.svg"], ["class", ["subexpr", "concat-strings", ["manageResources-itemForm-form-groupActions-icon ", ["subexpr", "if", [["get", "permission.permissionValue.permissionTarget.isClaimMode", ["loc", [null, [21, 275], [21, 330]]], 0, 0, 0, 0], "manageResources-itemForm-form-groupActions-icon--active", ""], [], ["loc", [null, [21, 271], [21, 392]]], 0, 0]], [], ["loc", [null, [21, 204], [21, 393]]], 0, 0]], ["loc", [null, [21, 168], [21, 395]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 6
            },
            "end": {
              "line": 31,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "manage-resources/claims-form/claim-value", [], ["claim", ["subexpr", "@mut", [["get", "permission.permissionValue.approvers", ["loc", [null, [30, 57], [30, 93]]], 0, 0, 0, 0]], [], [], 0, 0], "excludeGroup", true, "location", "at-permissionWithClaimForm"], ["loc", [null, [30, 8], [30, 151]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 6
            },
            "end": {
              "line": 43,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "select-2", [], ["value", ["subexpr", "@mut", [["get", "permission.permissionValue.approvers", ["loc", [null, [33, 14], [33, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "enabled", ["subexpr", "not", [["get", "disabled", ["loc", [null, [34, 21], [34, 29]]], 0, 0, 0, 0]], [], ["loc", [null, [34, 16], [34, 30]]], 0, 0], "optionIdPath", "id", "optionLabelPath", "name", "multiple", true, "query", "searchUserGroups", "placeholder", ["subexpr", "t", ["components.manage_resources.permissions_form.request_approvers_placeholder"], [], ["loc", [null, [39, 20], [39, 100]]], 0, 0], "class", "manageResources-itemForm-form-input at-permissionsForm"], ["loc", [null, [32, 8], [42, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 8
            },
            "end": {
              "line": 47,
              "column": 8
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "class", "button button--white at-buttonGroup at-permissionsForm at-claimToggler");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["toggleClaimModeForApprover", ["get", "permission", ["loc", [null, [46, 56], [46, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [46, 18], [46, 68]]], 0, 0], ["inline", "inline-svg", ["icons/user.svg"], ["class", ["subexpr", "concat-strings", ["manageResources-itemForm-form-groupActions-icon ", ["subexpr", "if", [["get", "permission.permissionValue.approvers.isClaimMode", ["loc", [null, [46, 269], [46, 317]]], 0, 0, 0, 0], "manageResources-itemForm-form-groupActions-icon--active", ""], [], ["loc", [null, [46, 265], [46, 379]]], 0, 0]], [], ["loc", [null, [46, 198], [46, 380]]], 0, 0]], ["loc", [null, [46, 162], [46, 382]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 52,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--column manageResources-itemForm-form-item--requestRule");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-item-requestRuleOptionWrapper");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "buttonGroup manageResources-itemForm-form-groupActions at-claimsForm");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label at-permissionFormApprovers");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-item-requestRuleOptionWrapper at-requestRuleApprovers");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "buttonGroup manageResources-itemForm-form-groupActions at-claimsForm");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [5]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
        morphs[3] = dom.createMorphAt(element4, 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "permission.permissionValue.permissionTarget.isClaimMode", ["loc", [null, [4, 10], [4, 65]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 4], [17, 11]]]], ["block", "unless", [["get", "preventClaimToggler", ["loc", [null, [20, 16], [20, 35]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [20, 6], [22, 17]]]], ["inline", "t", ["components.manage_resources.permissions_form.request_approvers_title"], [], ["loc", [null, [26, 80], [26, 156]]], 0, 0], ["block", "if", [["get", "permission.permissionValue.approvers.isClaimMode", ["loc", [null, [29, 12], [29, 60]]], 0, 0, 0, 0]], [], 3, 4, ["loc", [null, [29, 6], [43, 13]]]], ["block", "unless", [["get", "preventClaimToggler", ["loc", [null, [45, 18], [45, 37]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [45, 8], [47, 19]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});
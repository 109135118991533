define('scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-item'],
    userGroupSearcher: _ember['default'].inject.service(),
    permission: null,

    _searchUserGroups: function _searchUserGroups(query, deferred) {
      this.get('userGroupSearcher').search(query).then(function (data) {
        deferred.resolve(data);
      });
    },

    actions: {

      searchUserGroups: function searchUserGroups(query, deferred) {
        _ember['default'].run.debounce(this, '_searchUserGroups', query, deferred, 1000);
      },

      didSelectPermissionGroup: function didSelectPermissionGroup(permission, value) {
        permission.set('permissionValue', { identity: value.id, name: value.name });
      },

      didSelectPermissionTarget: function didSelectPermissionTarget(permission, value) {
        if (permission.get('permissionValue.permissionTarget')) {
          permission.set('permissionValue.permissionTarget', value);
        } else {
          permission.set('permissionValue', { permissionTarget: value });
        }
      },

      toggleClaimModeForPermissionTarget: function toggleClaimModeForPermissionTarget(permission) {
        var isClaimMode = permission.get('permissionValue.permissionTarget.isClaimMode') || false;

        if (!isClaimMode) {
          permission.set('permissionValue', {});
          permission.set('permissionValue.permissionTarget', {
            claimType: 'group',
            claimValue: '',
            isClaimMode: true
          });
        } else {
          permission.set('permissionValue.permissionTarget', null);
        }
      },

      toggleClaimModeForApprover: function toggleClaimModeForApprover(permission) {
        var isClaimMode = permission.get('permissionValue.approvers.isClaimMode') || false;

        if (!isClaimMode) {
          permission.set('permissionValue.approvers', {
            claimType: 'group',
            claimValue: '',
            isClaimMode: true
          });
        } else {
          permission.set('permissionValue.approvers', []);
        }
      }
    }
  });
});
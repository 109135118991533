define('scientia-resourcebooker/services/bulk-exporter', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var TASK_PROGRESS = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.TASK_PROGRESS_URL;

  var FILE_DOWNLOAD_URL = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_EXPORT.FILE_DOWNLOAD_URL;

  var CLAIMS_EXPORT_ALL = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_EXPORT.CLAIMS.ALL;
  var CLAIMS_EXPORT_SELECTED = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_EXPORT.CLAIMS.SELECTED;
  var CLAIMS_TEMPLATE = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_EXPORT.CLAIMS.TEMPLATE;

  var RESOURCE_TYPES_EXPORT_ALL = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_EXPORT.RESOURCES.ALL;
  var RESOURCE_TYPES_SELECTED = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_EXPORT.RESOURCES.SELECTED;
  var RESOURCE_TYPE_TEMPLATE = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_EXPORT.RESOURCES.TEMPLATE;

  var FIELDS_EXPORT_ALL = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_EXPORT.CUSTOM_FIELDS.ALL;
  var FIELDS_EXPORT_SELECTED = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_EXPORT.CUSTOM_FIELDS.SELECTED;

  var EXPORT_ALL_URLS = { claims: CLAIMS_EXPORT_ALL, resources: RESOURCE_TYPES_EXPORT_ALL, customFields: FIELDS_EXPORT_ALL };
  var EXPORT_SELECTED_URLS = { claims: CLAIMS_EXPORT_SELECTED, resources: RESOURCE_TYPES_SELECTED, customFields: FIELDS_EXPORT_SELECTED };

  var EXPORT_TEMPLATE_URLS = { claims: CLAIMS_TEMPLATE, resources: RESOURCE_TYPE_TEMPLATE };

  var TYPE_NAMES = { claims: 'user-groups', resources: 'resources', customFields: 'custom-fields' };
  var CUSTOM_FIELD_TYPE_NAMES = { FIELDS: 'no-mapping', FIELDS_WITH_MAPPING_DATA: 'with-mapping', MAPPING_FILE: 'mapping-file' };

  exports['default'] = _ember['default'].Service.extend({

    authenticatedRequest: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    getTaskProgress: function getTaskProgress(taskKey) {
      return this.get('authenticatedRequest').fetch(TASK_PROGRESS.loc(taskKey));
    },

    exportAll: function exportAll(_ref) {
      var type = _ref.type;

      var typeString = TYPE_NAMES[type];
      this.get('appInsights').trackEvent('bulk-export', { type: typeString, all: true });

      var url = EXPORT_ALL_URLS[type];
      return this.get('authenticatedRequest').fetch(url);
    },

    exportSelected: function exportSelected(_ref2) {
      var type = _ref2.type;
      var ids = _ref2.ids;

      var typeString = TYPE_NAMES[type];
      this.get('appInsights').trackEvent('bulk-export', { type: typeString, all: false, ids: ids });

      var url = EXPORT_SELECTED_URLS[type];
      return this.get('authenticatedRequest').post(url, ids);
    },

    getDownloadURL: function getDownloadURL(id) {
      var endpoint = FILE_DOWNLOAD_URL.loc(id);
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/' + endpoint;
      return url;
    },

    getDownloadURLForTemplate: function getDownloadURLForTemplate(_ref3) {
      var type = _ref3.type;

      var typeString = TYPE_NAMES[type];

      this.get('appInsights').trackEvent('bulk-import-template-download', { type: typeString });

      var endpoint = undefined;
      if (type === 'resources') {
        var institutionId = this.get('currentUser').getInstitutionId();
        endpoint = EXPORT_TEMPLATE_URLS[type].loc(institutionId);
      } else {
        endpoint = EXPORT_TEMPLATE_URLS[type];
      }
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/' + endpoint;
      if (url) {
        return url;
      }
    },

    // For the templates we don't need to "generate" a file and then download it, we call the endpoint directly for the
    // file download
    getDownloadURLForCustomFieldsTemplates: function getDownloadURLForCustomFieldsTemplates(type) {
      var typeString = CUSTOM_FIELD_TYPE_NAMES[type];
      this.get('appInsights').trackEvent('bulk-import-template-download', { type: 'custom-fields', file: typeString });

      var institutionId = this.get('currentUser').getInstitutionId();
      var endpoint = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_EXPORT.CUSTOM_FIELDS.TEMPLATE[type].loc(institutionId);
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/' + endpoint;
      return url;
    }

  });
});
define('scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/new/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {

    concurrencyGroupsPersister: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.resource_type.concurrency.group.new.page_title');
    },

    model: function model() {
      return _ember['default'].RSVP.hash({
        concurrencyGroup: this.store.createRecord('concurrencyGroup')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          error: null
        });
      }
    },

    actions: {
      createConcurrencyGroup: function createConcurrencyGroup(model) {
        var _this = this;

        var controller = this.controllerFor(this.routeName);

        if (_ember['default'].isEmpty(model.get('name'))) {
          controller.set('error', this.get('i18n').t('error_messages.concurrencygroupnoname').toString());
          return;
        }

        controller.set('error', null);
        controller.set('isSaving', true);
        model.save().then(function (_ref) {
          var id = _ref.id;

          _this.createSuccessFlash('concurrency-group');
          return _this.transitionTo('manage-resources.resource-type.concurrency.group.edit', id, { queryParams: { isNew: true } });
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.createErrorFlash('concurrency-group');
          }
        })['finally'](function () {
          controller.set('isSaving', false);
        });
      },

      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        var model = this.modelFor(this.routeName);
        if (model.concurrencyGroup.get('hasDirtyAttributes')) model.concurrencyGroup.rollbackAttributes();
        return true;
      }
    }
  });
});
define('scientia-resourcebooker/pods/components/container-sidebar/nav/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'ul',
    classNames: ['sidebarNav', 'at-sidebar'],
    currentUser: _ember['default'].inject.service(),

    notificationsCount: 0,

    canSeeAdminTab: _ember['default'].computed('currentUser.hasLoadedPermissions', function () {
      return this.get('currentUser').isResourceOwner() || this.get('currentUser').isTemplateOwner() || this.get('currentUser').isUserGroupOwner() || this.get('currentUser').isBookingsReporter() || this.get('currentUser').isAuditReporter() || this.get('currentUser').isConcurrencyManager();
    })
  });
});
define('scientia-resourcebooker/pods/manage-resources/reports/index/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], {

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.reports.page_title');
    },

    queryParams: {
      resume: { replace: true },
      bookingId: { replace: true },
      occurrenceId: { replace: true },
      selectedTab: { refreshModel: true }
    },

    can: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('can').can('access report')) {
        return this.transitionTo('logged-in');
      }
    }

  });
});
define("scientia-resourcebooker/locales/zh-cn/translations", ["exports"], function (exports) {
  /* jshint quotmark:double */
  /* jshint trailingcomma:false */
  exports["default"] = {
    "general": {
      "weekdays": "星期日,星期一,星期二,星期三,星期四,星期五,星期六",
      "weekdays_abbreviations": "周日,周一,周二,周三,周四,周五,周六",
      "months_abbreviations": "1 月,2 月,3 月,4 月,5 月,6 月,7 月,8 月,9 月,10 月,11 月,12 月",
      "generic_error": "发生错误，请稍后再试。",
      "session_timeout_error": "会话已过期。请重新登录。",
      "network_offline": "您处于离线状态。请检查网络连接。",
      "network_restored": "网络连接已恢复。",
      "yes": "是",
      "no": "否",
      "ok": "确定",
      "confirm": "确认",
      "cancel": "取消",
      "next": "下一页",
      "close": "关闭",
      "print": "打印",
      "undo": "还原",
      "save": "保存",
      "done": "完成",
      "continue": "继续",
      "generate": "生成",
      "refresh": "刷新",
      "save_back": "保存并关闭",
      "back": "返回",
      "are_you_sure": "是否确定？",
      "manage": "管理",
      "home": "主页",
      "delete": "删除",
      "edit": "编辑",
      "view": "查看",
      "clear": "清除",
      "beta": "Beta"
    },
    "helpers": {
      "formatted_property_value": {
        "yes": "是",
        "no": "否"
      }
    },
    "languages": {
      "language": "语言",
      "en": "English",
      "zh-cn": "中文",
      "da": "Dansk",
      "de": "Deutsch",
      "es": "Español",
      "nl": "Nederlands",
      "cy": "Cymraeg"
    },
    "mixins": {
      "resource_flashable": {
        "create_success": "{{entity}} 已成功创建。",
        "create_error": "{{entity}} 尚未创建。请稍后再试。",
        "edit_success": "{{entity}} 已成功更新。",
        "edit_error": "{{entity}} 尚未更新。请稍后再试。",
        "delete_success": "{{entity}} 已成功删除。",
        "delete_error": "{{entity}} 尚未删除。请稍后再试。",
        "flashable_resources": {
          "user_group": "用户组",
          "booking_type": "模板",
          "resource_type": "资源类型",
          "resource_group": "资源组",
          "resource": "资源",
          "booking_request": "预定请求",
          "booking_form": "预定表单",
          "concurrency_group": "“同一时间”组",
          "concurrency_configuration": "“同一时间”配置"
        }
      }
    },
    "date_formats": {
      "date_full": "YYYY/MM/DD",
      "hour_full": "HH:mm",
      "date_hour_full": "YYYY/MM/DD HH:mm",
      "midnight_twenty_four": "24:00",
      "report_month": "YYYY MMMM",
      "report_day": "YYYY MMMM dddd, D"
    },
    "calendar": {
      "dateFormats": {
        "date": {
          "short": "MMM D",
          "medium": "YYYY MMM D",
          "long": "YYYY MMMM dddd D",
          "short_with_weekday": "MMM D ddd"
        },
        "datetime": {
          "short": "MMM D H:mm",
          "medium": "YYYY MMM D H:mm",
          "long": "YYYY MMMM dddd D H:mm"
        },
        "time": {
          "shortA": "h:mmA",
          "short": "H:mm",
          "medium": "H:mm",
          "long": "HH:mm",
          "meridiem": "h A"
        },
        "day": "YYYY MMMM dddd, D",
        "week": "YYYY MMMM D",
        "month": "YYYY MMMM",
        "year": "YYYY"
      },
      "agenda": {
        "day_ago": "天前",
        "days_ago": "天前",
        "event_started": "此活动开始于",
        "event_continue": "此活动延续自前一天",
        "previous_days": "前些天"
      },
      "common": {
        "agenda": "议程",
        "today": "今天",
        "day": "天",
        "week": "周",
        "month": "月",
        "year": "年",
        "previous": "上一个",
        "next": "下一个",
        "uncategorized": "未分类",
        "all_day": "全天",
        "all_day_until": "全天 - 截至",
        "all_day_from": "全天 - 始于"
      },
      "time_periods": {
        "AM": "AM",
        "PM": "PM"
      },
      "month_view": {
        "more_events": "更多"
      },
      "agenda_view": {
        "no_events": "本月无活动"
      },
      "header": {
        "tags": {
          "concurrency": "并行活动",
          "resource": "资源",
          "template": "模板",
          "event": "活动",
          "booking": "预定",
          "general": "通用",
          "uncategorized": "未分类"
        }
      }
    },
    "notification_types": {
      "requests": "待审批",
      "more_info": "更多信息",
      "requested": "已请求",
      "accepted": "已接受",
      "rejected": "已拒绝",
      "cancelled": "已取消"
    },
    "error_messages": {
      "day_format": "MMMM D HH:mm",
      "resource_availability": "{{resourceType}}“{{resourceName}}”在 {{start}} 至 {{finish}} 期间不可用。",
      "minimum_days_in_advance": "此操作不适用于提前申请天数少于 {{days}} 天的预定。",
      "maximum_days_in_advance": "只可申请 {{date}} 日 之前的预定。",
      "minimum_time_in_advance_startofbooking": "此操作不适用于提前开始时间少于 {{minutes}} 分钟的预定。",
      "minimum_time_in_advance_endofbooking": "此操作不适用于提前结束时间少于 {{minutes}} 分钟的预定。",
      "maximum_time_in_advance_startofbooking": "预定的提前开始时间不得超过 {{minutes}} 分钟。",
      "maximum_time_in_advance_endofbooking": "预定的提前结束时间不得超过 {{minutes}} 分钟。",
      "booking_window": "预定必须于 {{startDate}} 至 {{endDate}} 之间申请。",
      "booking_window_multiple": "预定必须在以下日期范围内申请：",
      "booking_window_multiple_line": "{{startDate}} 至 {{endDate}}",
      "minimum_booking_duration": "最短持续时间为 1 分钟。",
      "maximum_duration": "预定的持续时间不得超过 {{minutes}} 分钟。",
      "minimum_duration": "预定的持续时间必须大于或等于 {{minutes}} 分钟。",
      "generic_error": "发生错误。",
      "booking_closed_date": "预定必须于 {{date}} 之前结束。",
      "booking_window_closed": "预定不得于 {{startDate}} 至 {{endDate}} 之间申请",
      "maximum_number_of_bookings": "允许超额预定的时段为 {{startDate}} - {{endDate}}。允许超额数为 {{number}}",
      "maxoccurrenceslimit": "请求的事项总数 ({{RequestedDatesCount}}) 已超出允许的最大数目 ({{MaxOccurrencesCount}})。请调整您的重复条件。",
      "booking_occurrence": "所选的日期/时间与已有预定事项发生冲突。",
      "usergroups_adminselfdelete": "您无法将自己移出管理组。",
      "novalidoccurrences": "所选的重复预定设置会导致无事项发生。请调整您的重复条件。",
      "recurring_booking_spanning_window": "您不得对 {{startDate}} 与 {{endDate}} 期间（含当日）以外的事项进行重复预定。",
      "booking_after_endtime": "预定开始时间晚于预定结束时间",
      "invalid_template": "不得创建预定类型为“{{bookingTemplate}}”的预定项",
      "required_resources": "预定需要包含 {{requiredMin}} 个类型为“{{resourceType}}”的资源",
      "invalid_requirement": "要求“{{requirement}}”不受预定类型“{{bookingType}}”的支持",
      "requirement_no_resourcetype": "资源要求需要指定资源类型",
      "invalid_resourcetype_on_template": "资源类型“{{resourceType}}”不受预定类型“{{bookingType}}”的支持",
      "invalid_number_resources": "为预定类型“{{bookingType}}”选择的资源数量（{{numberRequired}}）超出了有效范围（{{requiredMin}} - {{requiredMax}}）。",
      "template_validation": "资源“{{resourceName}}”（{{resourceType}}）对预定类型“{{templateName}}”无效",
      "external_resource_span_days": "对该资源的预定范围不得超过一天",
      "system_mode": "该应用程序目前处于封闭模式。无法进行预定",
      "resourcenotontemplate": "资源不属于所提供的预定类型",
      "usergroup_nameinuse": "已存在使用该名称的用户组。请输入新名称",
      "usergroup_nameinusedifferentapplication": "名称‘{{PermissionGroupName}}’已使用于‘{{Scope}}’。请输入新名称",
      "usergroup_noname": "用户组名称为必填项",
      "resourcetype_noname": "资源类型名称为必填项",
      "resourceType_customfield_invalidselectionfield": "自定义字段‘{{propertyName}}’至少需要一个选项",
      "resourcegroup_noname": "资源组名称为必填项",
      "resourcegroup_nameinuse": "已存在使用该名称的资源组。请输入新名称",
      "resource_noname": "资源名称为必填项",
      "usergroup_duplicateidentity": "身份已在使用中",
      "usergroup_noclaims": "每个用户组至少需要一个声明",
      "usergroup_everyonegroupnoedit": "“Everyone”组无法修改",
      "usergroup_systemgroupnodelete": "系统组无法删除",
      "usergroup_commaname": "用户组名不得包含逗号 (,)。",
      "resource_nameinuse": "已存在使用该名称的资源。请输入新名称",
      "resource_invalidemail": "值“{{EmailAddress}}”不是有效的电子邮件地址",
      "resourcetype_nameinuse": "已存在使用该名称的资源类型。请输入新名称",
      "bookingtypeunavailable": "当前的预定类型模式被设置为 ‘{{BookingTypeMode}}’ 。您目前无法进行预定",
      "bookingform_nameinuse": "已存在使用该名称的预定表单。请输入新名称",
      "bookingformvalidation_nonameonform": "预定表单名称为必填项",
      "bookingformvalidation_duplicatedshortdescription": "多个字段中含有值为“{{ShortDesc}}”的简短说明。简短说明必须唯一。",
      "resourcegroup_groupinuse": "资源组无法删除。它目前正在由 {{bookingTypes}} 使用",
      "resourcetype_customfield_invalidselectionfield": "自定义字段“{{propertyName}}”至少需要添加一个选项",
      "edit_unauthorised": "您没有执行此操作所需的权限",
      "bookingreferenceformaterror": "创建您的预定参考号时出现问题。请联系您的系统管理员。",
      "import_generatingcsvfile": "正在生成 CSV 文件...",
      "importusergroups_usergroupfetch": "正在提取要导出的用户组...",
      "importusergroups_usergroupcsv": "正在将用户组转换成 CSV 格式...",
      "importresource_resourcetypefetch": "正在提取要导出的资源类型...",
      "importresource_resourcefetch": "正在提取要导出的资源...",
      "importresource_resourcecsv": "正在将资源转换成 CSV 格式...",
      "concurrencygroupnoname": "“同一时间”组名称为必填项",
      "concurrencygroupduplicatename": "同名的“同一时间”组已存在",
      "concurrencygroupmultipleprimaryresources": "每个“同一时间”组只能有一项主要资源",
      "concurrencygroupmaxresources": "每个“同一时间”组最多只能有 {{MaxResources}} 项资源",
      "concurrencygroupunauthorisedresources": "“同一时间”组含有未经授权的资源类型成员",
      "concurrencygroupexternalresources": "“同一时间”组不能同时包含外部管理和非外部管理的资源",
      "concurrencyconfigurationnoname": "“同一时间”组配置为必填项",
      "concurrencyconfigurationduplicatename": "同名的“同一时间”配置已存在",
      "concurrencyconfigurationunauthorisedgroups": "“同一时间”配置中含有未经授权的“同一时间”组成员",
      "duplicate_name": "名称“{{name}}”已被使用。请输入新名称",
      "missing_name": "名称为必填或无效",
      "duplicate_identity": "身份为“{{identity}}”的实体已经存在",
      "missing_entities": "以下实体已不存在：“{{entityNames}}”",
      "resourcetype_valuetype_invalid": "值类型“{{valueType}}”无效",
      "resourceType_valueproperty_changed": "属性“{{resourcePropertyName}}”的值类型无法更改",
      "resourcegroup_invalidresource": "资源“{{resource}}”中没有资源类型“{{resourceType}}”",
      "resource_property_invalid": "属性“{{identity}}”不受资源类型“{{objectPropertyToDisplay}}”的支持",
      "resource_valueType_invalid": "值类型“{{valueType}}”无效",
      "resource_value_propertyinvalid": "值“{{selectedValue}}”不是属性“{{propertyName}}”的有效选项",
      "resource_concurrency": "以下资源因“同一时间”组规则原因无法一起预定：{{resourceNames}}",
      "date_time_formats": {
        "default": "MMMM D",
        "resource_availability": {
          "start": "MMMM D HH:mm",
          "finish": "MMMM D HH:mm"
        }
      }
    },
    "errors": {
      "description": "该字段",
      "inclusion": "{{description}} 未包含在列表中",
      "exclusion": "{{description}} 已预留",
      "invalid": "{{description}} 无效",
      "confirmation": "{{description}} 与 {{on}} 不匹配",
      "accepted": "{{description}} 必须接受",
      "empty": "{{description}} 不得为空",
      "blank": "{{description}} 不得为空白",
      "present": "{{description}} 必须为空白",
      "collection": "{{description}} 必须为集合",
      "singular": "{{description}} 不得为集合",
      "tooLong": "{{description}} 过长（最长为 {{max}} 个字符）",
      "tooShort": "{{description}} 过短（最短为 {{min}} 个字符）",
      "before": "{{description}} 必须早于 {{before}}",
      "after": "{{description}} 必须晚于 {{after}}",
      "wrongDateFormat": "{{description}} 的格式必须为 {{format}}",
      "wrongLength": "{{description}} 的长度错误（应为 {{is}} 个字符）",
      "notANumber": "{{description}} 必须为数字",
      "notAnInteger": "{{description}} 必须为整数",
      "greaterThan": "{{description}} 必须大于 {{gt}}",
      "greaterThanOrEqualTo": "{{description}} 必须大于或等于 {{gte}}",
      "equalTo": "{{description}} 必须等于 {{is}}",
      "lessThan": "{{description}} 必须小于 {{lt}}",
      "lessThanOrEqualTo": "{{description}} 必须小于或等于 {{lte}}",
      "otherThan": "{{description}} 必须不同于 {{value}}",
      "odd": "{{description}} 必须为奇数",
      "even": "{{description}} 必须为偶数",
      "positive": "{{description}} 必须为正数",
      "date": "{{description}} 必须为有效日期",
      "email": "{{description}} 必须为有效的电子邮件地址",
      "phone": "{{description}} 必须为有效的电话号码",
      "url": "该字段必须为有效的 url 且以 http:// 或 https:// 开头",
      "externalDataSourceFetchError": "无法从外部数据源提取数据。",
      "uniqueItemValue": "选项值必须唯一",
      "emptyItemValueText": "您必须提供每个项目的“标签”和“值”属性",
      "attachmentEmptyCustomExtensions": "您已选择自定义文件类型但未指定任何类型",
      "singleChoiceRequired": "您需要选择一个选项",
      "attachmentRequired": "您需要上传文件",
      "minimumIsLowerThanMaximum": "最小值不得高于最大值",
      "multipleChoiceMinRestriction": {
        "one": "您需要至少选择一个选项",
        "other": "您需要至少选择 {{count}} 个选项"
      },
      "multipleChoiceMaxRestriction": {
        "one": "您只能选择一个选项",
        "other": "您至多只能选择 {{count}} 个选项"
      },
      "multipleChoiceMinRestrictionSingle": "这是必填字段",
      "maxDecimalPlaces": "该数字的小数位不得超过 {{count}} 个",
      "numberInvalid": "该值不是有效数字"
    },
    "components": {
      "resources_meta": {
        "previous": "上一个",
        "next": "下一个",
        "items_per_page": "每页项目",
        "items_per_page_options": "10,25,50,100"
      },
      "time_picker": {
        "hour_format": "HH:mm"
      },
      "additional_resource_booking": {
        "input_placeholder": "从 {{bookingType}} 中选择资源"
      },
      "booking_request_edit_restrictions_modal": {
        "edit": "编辑",
        "booking_type_changed": "预定类型已更改",
        "rr_primary": "该预定类型在您预定后已被更改。无法编辑该预定项。",
        "rr_other": "该预定类型在您预定后已更改。更新预定时，以下资源会自动从该预定项中移除：",
        "rr_occurrence": "该预定类型在您预定后已被更改。无法编辑该预定项的单个事项。",
        "rr_multiple": "该预定类型在您预定后已更改。可能需要移除一个或多个资源来更新该预定项。此外，更新预定时，以下资源会自动从该预定项中移除：",
        "rr_max_limit_changed": "该预定类型在您预定后已更改。可能需要移除一个或多个资源来更新该预定项。"
      },
      "booking_request_form": {
        "date_format": "YYYY 年 MMM D 日",
        "name_placeholder": "预定标题",
        "from": "从",
        "start_day_placeholder": "选择开始日期",
        "to": "至",
        "end_day_placeholder": "选择结束日期",
        "repeat_booking": "重复该预定？",
        "update_recurrence_pattern": "更新事项模式",
        "additional_resources": "其他资源",
        "guests": "来宾",
        "select_guest": "选择来宾",
        "description": "描述",
        "book": "预定",
        "update": "更新",
        "title_required": "标题为必填项。",
        "from_time_required": "开始时间为必填项。",
        "to_time_required": "结束时间为必填项。",
        "to_time_before_start_time": "该预定的结束日期/时间必须晚于开始时间",
        "recurring_error": "“重复”项下的一个或多个选项不正确。",
        "min_max": "最小：{{min}}，最大：{{max}}",
        "limit_reached": "您已达到限值",
        "adjusted_times": "预定日期/时间已根据该预定类型的要求自动调整。请在更新预定前检查确认。",
        "bobo": {
          "title": "代表他人预定？",
          "placeholder": "每个电子邮件地址占一行",
          "no_emails": "您已选择代表他人预定，但未提供任何电子邮件地址。",
          "invalid_emails": "下列地址似乎不正确：{{emails}}"
        },
        "base": {
          "title": "以他人名义预定？",
          "email_placeholder": "电子邮件地址",
          "identifier_placeholder": "新所有者标识符",
          "name_placeholder": "新所有者姓名",
          "no_identity_or_email": "以他人名义预定时，需要提供新所有者的标识符、电子邮件和姓名"
        },
        "edit": {
          "title": "编辑 {{resourceName}} 的预定请求"
        },
        "create": {
          "title": "创建 {{resourceName}} 的预定请求"
        },
        "fields": {
          "attachment": {
            "remove_file": "移除文件",
            "max_file_size": "文件最大大小：{{size}}KB",
            "allowed_file_types": "允许文件类型：{{types}}",
            "errors": {
              "bookingformvalidation_invalidfiletype": "上传的文件类型不受支持。",
              "bookingformvalidation_filesize": "上传的文件大小超出了限值"
            }
          },
          "single_choice": {
            "typeaheadSearchingText": "正在搜索...",
            "typeaheadNoMatchesText": "未找到匹配项",
            "typeaheadErrorText": "加载失败"
          }
        }
      },
      "recurring_event_fields": {
        "start_date": "开始日期",
        "start_week_commencing": "起始周 - 开始日期",
        "end_week_commencing": "结束周 - 结束日期",
        "limited_pattern_message": "该预定类型仅限于有限的每周重复选项",
        "frequency": "频率",
        "from_time": "开始时间",
        "to_time": "结束时间",
        "daily": "每天",
        "weekly": "每周",
        "monthly": "每月",
        "yearly": "每年",
        "every": "每",
        "days": {
          "one": "天",
          "other": "天"
        },
        "weeks": {
          "one": "周一次，日期为",
          "other": "周一次，日期为"
        },
        "months": {
          "one": "个月一次，日期为",
          "other": "个月一次，日期为"
        },
        "years": {
          "one": "年一次，日期为",
          "other": "年一次，日期为"
        },
        "exclude_weekends": "不含周末",
        "specific_day": "具体日子",
        "period": "周期",
        "repeat_until": "重复直至",
        "until_date": "日期",
        "until_nr_occurrences": "事项数",
        "occurrences": "事项",
        "weekday": "工作日",
        "day_of_month": "月中某日",
        "until_end_date": "结束日期",
        "validation": "“{{field}}”不正确",
        "no_occurrences_error": "当前设置会导致无有效事项发生。请调整您的设置。",
        "description_daily": {
          "one": "每天一次，有效期为 {{startDate}} 至 {{endDate}}，{{startTime}} 至 {{endTime}}",
          "other": "每 {{count}} 天一次，有效期为 {{startDate}} 至 {{endDate}}，{{startTime}} 至 {{endTime}}"
        },
        "description_weekly": {
          "one": "每周一次，日期为{{weekdays}}，有效期为 {{startDate}} 至 {{endDate}}，{{startTime}} 至 {{endTime}}",
          "other": "每 {{count}} 周一次，日期为{{weekdays}}，有效期为 {{startDate}} 至 {{endDate}}，{{startTime}} 至 {{endTime}}"
        },
        "description_monthly_specific_day": {
          "one": "每月第 {{day}} 日，有效期为 {{startDate}} 至 {{endDate}}，{{startTime}} 至 {{endTime}}",
          "other": "每 {{count}} 个月第 {{day}} 日，有效期为 {{startDate}} 至 {{endDate}}，{{startTime}} 至 {{endTime}}"
        },
        "description_monthly_period": {
          "one": "每月{{period}}个 {{day}}，有效期为 {{startDate}} 至 {{endDate}}，{{startTime}} 至 {{endTime}}",
          "other": "每 {{count}} 个月{{period}}个{{day}}，有效期为 {{startDate}} 至 {{endDate}}，{{startTime}} 至 {{endTime}}"
        },
        "monthly_periods": "第一,第二,第三,第四,最后一个",
        "errors": {
          "maxoccurrenceslimit": "请求的总发生次数 ({{RequestedDatesCount}}) 已超出允许的最大数目 ({{MaxOccurrencesCount}})。请调整您的搜索条件。",
          "novalidoccurrences": "所选的重复预定设置会导致无事项发生。请调整您的重复条件。"
        }
      },
      "week_time_pattern": {
        "unavailable": "不可用",
        "pending_addition": "待添加",
        "pending_removal": "待移除"
      },
      "booking_report_list": {
        "date_format_span": "HH:mm（YYYY 年 MMM D 日）",
        "date_format_span_twenty_four": "kk:mm（YYYY 年 MMM D 日）",
        "time_format": "HH:mm"
      },
      "booking_request_overview": {
        "description": "描述",
        "status": "状态：",
        "statuses": {
          "MoreInfoRequested": "已请求更多信息",
          "Requested": "已请求",
          "Accepted": "已接受",
          "Rejected": "已拒绝",
          "Cancelled": "已取消",
          "Changed": "已更改"
        },
        "statuses_messages": {
          "accepted": "该预定已接受",
          "requested": "该预定正在审批",
          "rejected": "该预定已拒绝",
          "cancelled": "该预定已取消",
          "changed": "该预定已更改",
          "moreinforequested": "该预定需要提供更多信息才能接受"
        },
        "no_history": "该预定无可用历史记录",
        "history": {
          "date_format": "YYYY/MM/DD HH:mm:ss",
          "notes": "提示：{{notes}}",
          "Requested": "{{actionedBy}} 已请求预定。",
          "Accepted": "{{actionedBy}} 已接受预定。",
          "Cancelled": "{{actionedBy}} 已取消预定。",
          "Rejected": "{{actionedBy}} 已拒绝预定。",
          "MoreInfoRequested": "{{actionedBy}} 已请求更多信息：{{notes}}"
        },
        "title": "预定请求概览",
        "date_format": "YYYY 年 MMM D 日",
        "date_format_full": "YYYY 年 MMM D 日 HH:mm",
        "time_format": "HH:mm",
        "cancel_booking": "取消",
        "cancel_series": "取消系列",
        "this_occurrence": "该事项",
        "entire_series": "整个系列",
        "edit_booking": "编辑",
        "edit_series": "编辑系列",
        "view_history": "查看历史记录",
        "hide_history": "隐藏历史记录",
        "resource_list_title": "资源",
        "booking_history_title": "通知历史记录",
        "owner": "预定人：",
        "revised": "修订人：",
        "reference": "参考：",
        "behalf_of": "委托人：",
        "occurrences": "事项",
        "show_all": "显示所有",
        "booking_repeats": "该预定的重复频率",
        "edited_note": "事项的原始日期/时间已被编辑。",
        "occurrence_date_format": "YYYY/MM/DD",
        "occurrence_time_format": "HH:mm",
        "notification_history_datetime_format": "YYYY/MM/DD - HH:mm",
        "revision": "修订",
        "revision_date_format": "YYYY/MM/DD HH:mm",
        "revision_note": "您正在查看 {{date}} 的预定修订情况",
        "cannot_edit_due_to_recurring_title": "无法编辑预定",
        "cannot_edit_due_to_recurring_message": "无法编辑预定。您没有进行定期预定的权限",
        "booking_summary": "预定摘要"
      },
      "calendar_modal": {
        "title": "订阅",
        "subscribe": "订阅",
        "copy": "复制",
        "regenerate": "重新生成",
        "subscribe_to_calendar_title": "订阅您的日历",
        "subscribe_text_with_icons": "您可以使用任何接受 iCal 格式的应用程序订阅自己的日历。您的应用程序将收到日历更新。单击下面的应用程序图标。",
        "subscribe_text_no_icons": "您可以使用任何接受 iCal 格式的应用程序订阅自己的日历。您的应用程序将收到日历更新。",
        "subscribe_default_title": "使用默认的日历应用程序订阅",
        "subscribe_default_text": "尝试下面的“订阅”按钮。您的浏览器将尝试使用默认的日历应用程序来订阅日历。",
        "subscribe_manually_title": "使用 URL 手动订阅",
        "subscribe_manually_text": "如果要手动订阅日历，大多数日历应用程序都支持使用 URL 进行订阅。复制下面的 URL，然后按照日历应用的订阅说明进行操作。",
        "regenerate_subscription_title": "重新生成我的订阅 URL",
        "regenerate_subscription_text": "您可以重新生成您的订阅 URL。这样可能会断开您现有订阅的链接，您可使用新 URL 重新订阅。",
        "regenerate_subscription_confirm": "重新生成订阅 URL 后，日历应用程序中的所有现有订阅将不再收到更新。是否确定？",
        "regenerate_subscription_success": "您的订阅 URL 已重新生成。以前的所有订阅将不再收到更新。请重新订阅。",
        "regenerate_subscription_error": "无法重新生成订阅 URL。请稍后再试。",
        "message_copied": "URL 已复制到剪贴板。",
        "message_not_copied": "您的浏览器不支持自动复制文本。请手动复制 URL。",
        "close": "关闭",
        "note_copy": "注意：某些浏览器可能会阻止使用“复制”按钮来复制链接。在这种情况下，请选择文本并手动复制。",
        "icon_labels": {
          "apple": "Apple",
          "google": "Google",
          "office365": "Office 365",
          "outlook": "Outlook",
          "outlookonline": "Outlook（在线）",
          "yahoo": "Yahoo"
        }
      },
      "event_overview": {
        "owner": "所有者：",
        "location": "地点：",
        "description": "描述：",
        "hostKey": "主键：",
        "event_type": "活动类型："
      },
      "rb_calendar_day_view": {
        "date_format": "MMMM dddd Do"
      },
      "rb_datepicker": {
        "pick_a_date": "选择日期",
        "date_format": "YYYY-MM-DD"
      },
      "rb_select": {
        "placeholder": "选择选项"
      },
      "user_group_searcher": {
        "title": "添加用户组",
        "search_placeholder": "搜索用户组...",
        "empty_message": "在下方的输入内容中搜索用户组。",
        "cancel": "取消",
        "save": "添加"
      },
      "icon_selector": {
        "title": "选择图标",
        "cancel": "取消",
        "submit": "设置图标",
        "icon_loading_error": "加载图标时出现错误",
        "select_icon_placeholder": "选择包",
        "categories": {
          "all": "所有",
          "custom": "自定义",
          "accessibility": "辅助功能",
          "building": "建筑",
          "miscellaneous": "经典",
          "food_and_drink": "食物和饮料",
          "kitchen": "厨房",
          "meetings": "会议",
          "medical": "医药",
          "music": "音乐",
          "school": "学校",
          "sports": "体育",
          "technology": "技术",
          "science": "科学"
        }
      },
      "image_form_preview": {
        "select_image": "选择图片",
        "select_another_image": "选择另一个图片"
      },
      "add_unavailability_slot": {
        "title": "添加不可用日期：{{date}}",
        "title_no_date": "添加不可用性",
        "cancel": "取消",
        "add": "添加",
        "unavailable_from": "不可用从",
        "unavailable_to": "至",
        "date_format": "MMMM dddd, Do",
        "user_group": "用户组：{{userGroup}}",
        "end_cannot_be_before": "结束时间不得早于开始时间",
        "slot_conflict": "您尝试添加的不可用时间段与现有时间段（{{startTime}} - {{endTime}}）存在冲突。请先将其删除或选择其他范围。",
        "datepicker_format": "YYYY/MM/DD HH:mm"
      },
      "remove_unavailability_slot": {
        "title": "移除不可用日期：{{date}}",
        "title_no_date": "移除不可用性",
        "cancel": "取消",
        "delete": "删除",
        "date_format": "MMMM dddd, Do",
        "datetime_format": "YYYY/MM/DD HH:mm",
        "unavailable_from": "从：{{date}}",
        "unavailable_to": "至：{{date}}",
        "unavailable_all_day": "此时间段持续全天",
        "time_format": "HH:mm",
        "pending_removal": "待移除",
        "pending_addition": "待添加",
        "delete_confirmation": "是否确定要移除此时间段？",
        "slot_pending_removal": "您已将此时间段标记为待移除。",
        "slot_pending_addition": "您已将此时间段标记为待添加。",
        "user_group": "用户组：{{userGroup}}"
      },
      "confirm_modal": {
        "cancel": "否",
        "ok": "是"
      },
      "confirm_reject_request": {
        "title": "确认拒绝",
        "message": "是否确定要拒绝该预定？",
        "cancel": "取消",
        "ok": "拒绝"
      },
      "confirm_accept_request": {
        "title": "确认接受",
        "message": "是否确定要接受该预定？",
        "cancel": "取消",
        "ok": "接受"
      },
      "more_info_request": {
        "title": "确认请求",
        "write_note": "为该用户编写备注",
        "cancel": "取消",
        "ok": "发送"
      },
      "notification_notes_modal": {
        "title": "详情",
        "close": "关闭"
      },
      "item_sort_modal": {
        "sort_items": "排序项目",
        "description": "使用控件或拖放操作来更改项目的顺序",
        "name_asc": "A-Z 排序",
        "name_desc": "Z-A 排序"
      },
      "add_to_resource_group": {
        "title": "对资源组的资源分组",
        "message": {
          "one": "是否确定要对此组中的资源分组？",
          "other": "是否确定要对这 {{count}} 个组中的资源分组？"
        },
        "empty_message": "在下方的输入内容中搜索资源组。要创建新组，请使用资源类型列表页面的“创建组”按钮。",
        "cancel": "取消",
        "save": "保存"
      },
      "container_sidebar": {
        "skip_to_content": "跳至主要内容",
        "meta": {
          "help": "帮助",
          "contact_us": "联系我们"
        },
        "nav": {
          "bookings": "进行预定",
          "my_bookings": "我的预定",
          "notifications": "通知",
          "administration": "管理",
          "manage_resources": "资源",
          "manage_booking_types": "模板",
          "manage_user_groups": "用户组",
          "manage_booking_forms": "表单",
          "profile": "个人资料",
          "reports": "报告",
          "system": "系统"
        },
        "user": {
          "profile": "我的资料",
          "logout": "注销"
        }
      },
      "date_input": {
        "date_format": "YYYY-MM-DD"
      },
      "rb_calendar": {
        "unavailable": "不可用",
        "agenda_view": {
          "date_format": "YYYY MMMM DD",
          "hour_format": "HH:mm"
        }
      },
      "date_period_selector": {
        "start_date": "开始日期",
        "end_date": "结束日期",
        "timeframe": "时间段",
        "timeframe_label": "时间段：",
        "today": "今天",
        "next_seven_days": "未来 7 天",
        "next_month": "下个月",
        "last_seven_days": "过去 7 天",
        "last_month": "上个月",
        "specific_range": "特定日期范围",
        "specific_range_date_format": "YYYY/MM/DD HH:mm"
      },
      "logout_modal": {
        "single_logout": "注销我的所有其他服务。"
      },
      "help_modal": {
        "title": "帮助",
        "no_help": "您当前无法在此页面上获取帮助。"
      },
      "download_modal": {
        "title": "文件下载",
        "click_download": "单击下面链接以下载文件。",
        "download_file": "下载文件",
        "download_expired": "您的下载已过期。请再次单击生成。"
      },
      "user_profile_form": {
        "edit_profile": "编辑个人资料",
        "language": "语言",
        "language_information": "请选择本网站的显示语言",
        "edit_informations": "编辑您的概况",
        "notifications": "通知",
        "notifications_informations": "指定您希望提前收到预定提醒的时机，确保在自己最需要的时候收到提醒。",
        "logout": "注销",
        "name": "全名",
        "remind_me": "我想要收到提醒",
        "dont_remind_me": "我不想收到提醒",
        "my_bookings": "我的预定",
        "fifteen_minutes": "提前 15 分钟",
        "one_hour": "提前 1 小时",
        "one_day": "提前 1 天",
        "save": "保存",
        "cancel": "取消"
      },
      "user_profile_info": {
        "title": "用户资料信息",
        "given_name": "名",
        "name_identifier": "名字标识符",
        "email": "电子邮件地址",
        "user_groups": "用户组",
        "ad_groups": "目录服务组"
      },
      "resource_filters": {
        "refine_search": "优化搜索",
        "single": "单次",
        "recurring": "重复",
        "search_recurring": "搜索重复",
        "availability": "可用性",
        "duration_item_labels": "15 分钟,30 分钟,1 小时,2 小时,3 小时,全天",
        "duration_items": {
          "0": "全天",
          "15": "15m",
          "30": "30m",
          "60": "1 小时",
          "120": "2 小时",
          "180": "3 小时"
        },
        "available_now": "现在可用",
        "available_now_with_all_day": "当天其他时间",
        "span_multiple_days": "持续数天",
        "start_date": "开始日期",
        "end_date": "结束日期",
        "set_time": "设置具体时间",
        "from": "从：",
        "to": "至：",
        "from_time": "选择可选开始时间",
        "to_time": "选择可选结束时间",
        "duration": "持续时间",
        "select": "选择",
        "reset_all": "重设所有",
        "number": {
          "minimum": "最小值",
          "maximum": "最大值"
        }
      },
      "resources_grid": {
        "resource_in_multiple_booking_types": "此资源关联了多个预定类型。请选择您想用的类型。",
        "cancel": "取消",
        "resource_disabled": "预定类型不可用",
        "image_for_template": "{{name}} 的图片",
        "resource_disabled_admin_extra": "作为管理员，您还想继续吗？"
      },
      "resources_list": {
        "load_more": "加载更多",
        "items": {
          "folder": "文件夹",
          "empty": "没有与筛选器匹配的项目",
          "show_properties": "显示属性",
          "groups": "组"
        },
        "filters": {
          "filter": "筛选：",
          "available_now": "现在可用",
          "span_multiple_days": "持续数天",
          "first_day": "开始日期",
          "last_day": "结束日期",
          "from_time": "从",
          "to_time": "至",
          "duration": "持续时间",
          "true": "是",
          "duration_item_labels": "全天,15 分钟,30 分钟,1 小时,2 小时,3 小时"
        },
        "search": {
          "search_resource": "搜索资源",
          "search_resources_and_groups": "搜索资源或组",
          "search_groups": "搜索用户组"
        }
      },
      "resource_tree": {
        "no_data": "没有数据",
        "no_results": "当前的搜索条件未返回任何结果。请重试或清除搜索项。",
        "clear_search": "清除搜索",
        "error_loading": "加载数据时出错",
        "resourcetypes": "资源类型",
        "resourcegroups": "资源组",
        "searchresults": "搜索结果",
        "concurrencygroups": "“同一时间”组",
        "resources": "资源",
        "add": "添加",
        "remove": "移除",
        "selection_list": {
          "selection": "选择",
          "clear": "清除",
          "sort": "排序",
          "no_items": "没有项目",
          "resources": "资源",
          "resourcetypes": "资源类型",
          "resourcegroups": "资源组",
          "concurrencygroups": "“同一时间”组",
          "primary": "主要",
          "make_primary": "切换主要项目",
          "primary_set": "项目已设置为主要",
          "primary_unset": "项目不再是主要"
        },
        "search": {
          "search": "搜索",
          "search_placeholder": "搜索对象",
          "no_items": "没有项目",
          "search_type": "搜索类型",
          "everything": "所有",
          "resources": "资源",
          "resourcetypes": "资源类型",
          "resourcegroups": "资源组",
          "concurrencygroups": "“同一时间”组"
        }
      },
      "notifications_list": {
        "waiting_approval": "正在等您审批",
        "activity": "您的活动",
        "search_results": "搜索结果：",
        "send_more_infos": "发送更多信息",
        "view_notes": "查看备注",
        "tabs": {
          "requests": "待审批",
          "more_info": "更多信息",
          "requested": "已请求",
          "accepted": "已接受",
          "rejected": "已拒绝",
          "cancelled": "已取消",
          "all": "所有",
          "search": "结果"
        }
      },
      "notification": {
        "day_format": "YYYY MMMM dddd D",
        "date_time_format_full": "YYYY 年 MMMM dddd D 日 H:mm",
        "hour_format": "HH:mm",
        "created": "创建时间：",
        "date_format": "YYYY/MM/DD HH:mm:ss",
        "booked_by": "预定人 {{name}} ({{email}})",
        "reference": "参考：",
        "descriptions": {
          "self": {
            "description": "您于 {{day}} 预定的 {{resource}}（{{from}} 至 {{to}}）{{tenseWithStatus}}。",
            "description_span_multiple_days": "您预定的 {{resource}}（{{startDate}}{{from}} 至 {{endDate}}）{{to}}{{tenseWithStatus}}。"
          },
          "third_party": {
            "description": "预定的{{resource}}在{{day}}从{{from}}至{{to}}{{tenseWithStatus}}",
            "description_span_multiple_days": "预定的 {{resource}}（{{startDate}}{{from}} 至 {{endDate}}）{{to}}{{tenseWithStatus}}。"
          },
          "tense_with_status": {
            "was": "已{{status}}{{actionedInfo}}",
            "has_been": "已{{status}}{{actionedInfo}}",
            "more_info": "需要提供更多信息以供审批"
          },
          "actioned": {
            "actioned_by": "由 {{actionedBy}} 于 {{actionedDate}}"
          }
        },
        "types": {
          "requested": "请求",
          "rejected": "拒绝",
          "accepted": "接受",
          "cancelled": "取消",
          "changed": "更改",
          "moreinforequested": "请求提供更多信息"
        },
        "flash_messages": {
          "unable_to_fetch_booking": "目前无法显示预定详情。请稍后再试。",
          "accepted_booking": "您已经接受了该预定。",
          "rejected_booking": "您已经拒绝了该预定。",
          "moreinfo_booking": "您已经请求提供该预定的更多信息",
          "error_accept_booking": "目前无法接受该预定。",
          "error_reject_booking": "目前无法拒绝该预定。",
          "error_moreinfo_booking": "目前无法请求提供该预定的更多信息。"
        },
        "send_more_infos": {
          "title": "发送更多信息",
          "cancel": "取消",
          "submit": "发送",
          "message": "消息",
          "reply_label": "答复"
        },
        "request_item": {
          "start_date_time": "YYYY 年 MMM D 日 h:mmA",
          "end_date_time": "YYYY 年 MMM D 日 h:mmA",
          "accept": "接受",
          "reject": "拒绝",
          "request_more_information": "请求提供更多信息",
          "repeat_booking": "这是重复预定，重复发生 {{count}} 次。"
        },
        "activity_item": {
          "start_date_time": "YYYY 年 MMM D 日 h:mmA",
          "end_date_time": "YYYY 年 MMM D 日 h:mmA"
        }
      },
      "notification_searcher": {
        "search": "搜索",
        "search_type": "搜索类型",
        "search_value": "输入搜索术语",
        "pick_date": "选择日期",
        "date_format": "YYYY 年 MMM D 日 HH:mm",
        "search_options": {
          "event_name": "预定标题",
          "notification_type": "通知类型",
          "booker_name": "预定人姓名",
          "resource_name": "资源名称",
          "booking_reference": "预定参考",
          "actioned_by": "执行人",
          "booking_date_start_range": "预定日期晚于：",
          "booking_date_end_range": "预定日期早于："
        }
      },
      "permissions_summary": {
        "administrative": "管理的",
        "booking": "预定",
        "user_groups": "用户组",
        "no_permissions": "无相关权限",
        "no_groups": "无相关用户组",
        "templates": "模板",
        "resource_type": "资源类型",
        "resource_groups": "资源组",
        "resource": "资源",
        "approvers": "批准人："
      },
      "toggle_switch": {
        "yes": "是",
        "no": "否",
        "yes_short": "是",
        "no_short": "否"
      },
      "manage_resources": {
        "item_types_list": {
          "empty": "没有可显示的 {{type}}"
        },
        "resource_types_list": {
          "manage_resources": "管理资源"
        },
        "resources_list": {
          "empty": "没有可显示的资源",
          "add_to_resource_group": "添加至组"
        },
        "user_group_new": {
          "title": "指定以下对象的主要信息：新用户组"
        },
        "user_group_edit": {
          "title": "更新以下对象的主要信息：{{name}}"
        },
        "user_group_form": {
          "name": "组名称",
          "deprecated_role": "此角色将在以后的版本中弃用。相关信息请参阅“管理员指南”。"
        },
        "claims_form_new": {
          "title": "指定以下对象的 Claim：新用户组"
        },
        "claims_form_edit": {
          "title": "指定以下对象的 Claim：{{name}}"
        },
        "claims_form": {
          "add_item_instruction": "单击下方按钮添加新 Claim",
          "add_item": "添加 Claim",
          "confirm_delete": "是否确定要删除该 Claim？这些 Claim 仅在您单击保存按钮时才会保存。",
          "claim_types": {
            "group": "Group",
            "directoryservicegroup": "Group",
            "nameidentifier": "Name identifier",
            "emailaddress": "Email address",
            "givenname": "Given name"
          },
          "too_many_claims": "共有 {{count}} 个声明要显示。请稍候。"
        },
        "notification_templates": {
          "select_template": "在列表中选择一个模板",
          "toggle_on_title": "使用自定义模板？",
          "toggle_off_title": "使用系统模板？",
          "toggle_off_message": "您是否要禁用此预定模板的自定义通知并还原为系统通知？",
          "toggle_on_message": "您是否要启用此预定模板的自定义通知并覆盖系统通知？",
          "copy_from_system": "从系统复制",
          "copy_all_from_system": "从系统复制全部",
          "copy_from_system_single_title": "从系统复制模板",
          "copy_from_system_single_message": "此操作将替换此预定模板的当前通知模板。是否确定？",
          "copy_from_system_all_title": "从系统复制所有模板",
          "copy_from_system_all_message": "此操作将替换此预定模板的所有通知模板。是否确定？",
          "names": {
            "booker": "预定人",
            "approver": "批准人",
            "resource": "资源",
            "bookingrequested": "预定已请求",
            "bookingaccepted": "预定已接受",
            "bookingrejected": "预定已拒绝",
            "bookingchanged": "预定已更改",
            "bookingcancelled": "预定已取消",
            "bookinginforequest": "已请求提供更多预定信息"
          },
          "editor": {
            "subject": "主题",
            "template": "模板",
            "from": "发件人",
            "cc": "抄送",
            "bcc": "密送",
            "email_placeholder": "email1@mail.com, email2@mail.com, email3@mail.com",
            "from_email_placeholder": "bookings_team@mail.com",
            "send_sample": "发送范本",
            "save_template": "保存模板",
            "send_email": "发送电子邮件"
          },
          "flash_messages": {
            "saved": "该模板已成功保存",
            "error_saving": "该模板无法保存。请稍后再试",
            "copy_from_system_ok": "模板已成功复制",
            "copy_from_system_error": "无法从系统复制模板。请稍后再试。"
          },
          "override_system_templates": "覆盖系统模板"
        },
        "permissions_form": {
          "add_item_instruction": "单击下方按钮来添加新权限",
          "add_item": "添加权限",
          "confirm_delete": "是否确定要删除此权限？这些权限仅在您单击保存按钮后才会保存。",
          "request_approvers_title": "批准人",
          "request_target_placeholder": "选择一个用户组",
          "request_approvers_placeholder": "选择一个用户组",
          "permissions_validation_error": "一个或多个权限无效或为空。",
          "permission_deprecated": "此权限将在以后的版本中弃用。相关信息请参阅“管理员指南”。",
          "show_permissions_summary": "显示权限摘要",
          "permission_types": {
            "read": "读取",
            "view": "查看",
            "write": "写入",
            "show": "显示",
            "book": "预定",
            "approve": "批准",
            "request": "请求",
            "base": "以他人名义预定",
            "bobo": "代表以下人士预定",
            "edit_claims": "编辑声明",
            "edit_all": "编辑全部",
            "edit_custom_fields": "编辑自定义字段",
            "edit_description": "编辑描述",
            "edit_status": "编辑状态",
            "edit_calendar": "编辑日历",
            "edit_availability": "编辑可用性",
            "edit_resource_restrictions": "编辑资源限制",
            "edit_permissions": "编辑权限",
            "edit_notifications": "编辑通知",
            "edit_rules": "编辑规则",
            "manage_groups": "管理群组",
            "manage_resources": "管理资源",
            "view_calendar": "查看日历",
            "view_bookings_report": "查看预定报告",
            "view_audit_report": "查看审计报告",
            "manage_bookings": "管理预定",
            "edit_resources": "编辑资源",
            "view_calendars": "资源——查看日历",
            "resources_view_bookings_report": "资源 — 查看预定报告",
            "resources_view_audit_report": "资源 — 查看审计报告",
            "resources_manage_bookings": "资源——管理预定",
            "resources_edit_description": "资源——编辑描述",
            "resources_edit_custom_fields": "资源——编辑自定义字段",
            "resources_edit_availability": "资源——编辑可用性",
            "resources_edit_permissions": "资源——编辑权限",
            "resources_edit_all": "资源——编辑全部",
            "recurring": "重复"
          }
        },
        "booking_type_new": {
          "title": "指定以下对象的主要信息：新模板"
        },
        "booking_type_edit": {
          "title": "更新以下对象的主要信息：{{name}}"
        },
        "booking_type_form": {
          "name": "模板名称",
          "description": "描述",
          "image_uri": "图片",
          "booking_form": "预定表单",
          "pick_booking_form": "选择自定义预定表单"
        },
        "booking_type_manage_status": {
          "title": "更新以下对象的状态：{{name}}",
          "mode": "模式",
          "mode_description": "选择预定类型的模式",
          "mode_placeholder": "选择模式",
          "offline": "离线",
          "offline_description": "——预定类型不可用",
          "hidden": "隐藏",
          "hidden_description": "——预定类型仅对管理员可用",
          "disabled": "禁用",
          "disabled_description": "——预定类型可见但为禁用状态",
          "live": "在线",
          "live_description": "——预定类型可用",
          "popup": "弹出窗口",
          "banner": "标语",
          "help": "帮助消息",
          "help_text": "帮助文本",
          "help_description": "为用户提供针对此类预定的有效指导",
          "information": "信息消息",
          "information_description": "提供应引起预定者注意的具体信息",
          "alert": "提醒消息",
          "alert_description": "提供应引起预定者注意的提醒消息",
          "enabled": "已启用",
          "type": "提醒消息类型",
          "type_description": "消息可以以横幅或弹出窗口的方式显示，用户必须将其关闭才能继续",
          "message": "消息",
          "custom_message": "自定义消息"
        },
        "booking_type_calendar_options": {
          "title": "日历选项",
          "description": "定义系统级别的日历选项",
          "override_system_settings": "覆盖系统设置",
          "start_time": "开始时间",
          "end_time": "结束时间",
          "show_weekends": "显示周末",
          "allow_span_multiple_days": "允许跨越多天预定",
          "span_multiple_days_disabled": "因外部应用程序限制，此选项已被禁用",
          "booking_times": "预定开始/结束时间",
          "booking_times_values": "00, 15, 30, 45|00, 30|00|30|15, 45|00, 10, 20, 30, 40, 50|00, 05, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55",
          "booking_times_values_labels": "整一刻|整点或半点|整点|半点|整一刻和整三刻|每 10 分钟|每 5 分钟",
          "available_minutes": "可用的分钟",
          "copy_from_system": "从系统复制"
        },
        "booking_form_new": {
          "title": "指定以下对象的主要信息：新表单"
        },
        "booking_form_edit": {
          "title": "更新以下对象的主要信息：{{name}}"
        },
        "booking_form_form": {
          "name": "表单名称",
          "description": "描述",
          "in_use_by": "正在使用，使用者",
          "not_in_use": "未使用"
        },
        "booking_form_tab": {
          "main": "主要",
          "add_item_instruction": "单击下方按钮来添加新字段",
          "add_item": "添加字段",
          "add_new": "添加新",
          "new_section_title": "新分段",
          "value_types": {
            "textbox": "文本框",
            "label": "标签",
            "attachment": "附件",
            "numeric": "数字",
            "single_choice": "单项选择",
            "multiple_choice": "多项选择",
            "url": "URL"
          }
        },
        "booking_form_list": {
          "used_by": {
            "one": "由 {{count}} 个模板使用",
            "other": "由 {{count}} 个模板使用",
            "zero": "未关联任何模板"
          }
        },
        "booking_form_field": {
          "field_type": "字段类型",
          "description": "说明",
          "description_explanation": "该字段说明会显示给预定人",
          "short_description": "短描述",
          "short_description_explanation": "对该字段的简短描述。该值不会显示给预定人。",
          "required": "为必填项",
          "required_explanation": "预定人必须填写所有必填字段才能创建预定",
          "active": "处于活动状态",
          "active_explanation": "预定表单上不会显示非活动字段",
          "reset_approval": "编辑时重设审批",
          "reset_approval_explanation": "在编辑某个预定时，对此字段的更改可要求对该预定重新审批",
          "externally_managed": "从外部管理",
          "externally_managed_explanation": "预定人的回复可以写入到外部管理系统的字段里",
          "pick_resource_type": "选择资源类型",
          "external_field_name": "字段名称",
          "dynamically_fetched_url": "从 URL 动态提取",
          "options": "选项",
          "done": "完成",
          "undo_changes": "还原",
          "textbox": {
            "max_character_limit": "最大字符数",
            "max_character_placeholder": "最大",
            "max_character_limit_explanation": "可对预定者的输入字符数应用相关限制",
            "multiline": "多行",
            "multiline_explanation": "此字段可以输入单行或多行文本"
          },
          "numeric": {
            "limit_range": "限制数值范围",
            "limit_range_explanation": "可对预定人的回复进行数值范围限制",
            "minimum": "最小值",
            "maximum": "最大值",
            "min": "最小",
            "max": "最大",
            "no_minimum": "无最小",
            "no_maximum": "无最大",
            "allow_decimal": "允许小数位",
            "allow_decimal_explanation": "数字字段可以是整数或包含最大位数可选的小数",
            "max_decimal_places": "最大小数位数",
            "max_decimal_places_explanation": "此字段可设置最大小数位数。将最大值设为零则表示无最大限制。"
          },
          "single_choice": {
            "control_type": "控制类型",
            "control_type_explanation": "表单上的单个选择字段可显示为单选按钮、下拉菜单或搜索字段。",
            "data_type": "数据类型",
            "data_type_explanation": "用于选择的项目可以手动输入或从 URL 中动态提取",
            "manual": "手动",
            "single_choice_type": "单项选择类型",
            "type_dropdown": "下拉",
            "type_radio_options": "单选选项",
            "type_search": "搜索",
            "url": "URL",
            "text": "标签",
            "value": "值",
            "add": "添加",
            "set_as_default_toast": "此项目已设为默认选择",
            "set_as_default_removed_toast": "此项目不再是默认选择",
            "set_as_default_tooltip": "将此项目设为默认选择"
          },
          "multiple_choice": {
            "item_will_be_checked_default": "项目将默认为选中",
            "item_will_be_unchecked_default": "项目将不再默认选中",
            "set_as_default_checked_tooltip": "将此项目设为默认选中",
            "nr_choices": "限制选择数目",
            "nr_choices_explanation": "此字段可设置最大和最小选择数目。将最小数目设为零则表示非必选项。将最大值设为零则表示无最大限制。"
          },
          "url": {
            "text_to_display": "要显示的文字",
            "text_to_display_explanation": "该文字显示为可单击的链接",
            "url": "网页地址",
            "url_explanation": "单击链接后会打开网页地址"
          },
          "attachment": {
            "max_file_size": "文件最大大小",
            "max_file_size_explanation": "指定附件的最大大小 (KB)。将最大值设为零则表示无最大限制",
            "unit_size_kilobyte": "KB",
            "restrict_filetypes": "限制文件类型",
            "restrict_filetypes_explanation": "附件可以限制为某些特定的文件类型",
            "types": "图片,Word 文档,Excel,PDF,文本,存档,自定义"
          }
        },
        "bulk_importer": {
          "in_progress_title": "请耐心等待……",
          "in_progress_description": "正在处理您的更改",
          "import_expired": "导入会话已过期。请重新导入",
          "import_finished_no_errors": "导入成功完成",
          "import_finished_with_errors": "导入已完成但有错误",
          "import_finished_with_errors_description": "导入已完成但有错误。请在下方查看",
          "time_elapsed": "时间流逝：",
          "valid": "有效",
          "invalid": "无效数据",
          "errors": "错误",
          "warnings": "警告",
          "total": "总计",
          "updates": "更新",
          "creates": "创建",
          "ignored": "忽略",
          "no_action": "无操作",
          "messages": {
            "import_invalid_header": "无效的CSV标头或文件不包含标头",
            "import_invalid_file_type": "无效文件类型： {{fileType}}",
            "import_empty_import_file": "导入文件中未检测到数据",
            "import_missing_task_key": "无法处理导入（未提供任务键）。请重试",
            "import_task_key_expired": "导入会话已过期。请重试",
            "import_bad_data": "读取“{{row}}”行上的数据时出错",
            "import_unreadable_data": "读取数据时出错：“{{exceptionMessage}}”",
            "import_exception_in_import": "{{objectName}}‘ 未能导入",
            "import_user_groups_missing_user_group_name": "尚未指定用户组名称",
            "import_user_groups_user_group_no_action": "无操作：用户组不存在",
            "import_user_groups_invalid_claim_value": "声明值无效：‘{{claimType}}’，‘{{claimValue}}’",
            "import_user_groups_invalid_claim_type": "声明类型无效：‘{{claimType}}’，‘{{claimValue}}’",
            "import_user_groups_user_group_no_valid_claims": "无操作：无声明可处理",
            "import_user_groups_user_group_created": "将创建用户组",
            "import_user_groups_user_group_created_claims": "{{claimsAdded}} 声明已添加",
            "import_user_groups_user_group_claims_replaced_deleted": "{{claimsDeleted}} 声明已删除",
            "import_user_groups_user_group_claims_replaced_added": "{{claimsAdded}} 声明已添加",
            "import_user_groups_user_group_claims_updated_existing": "无操作：{{claimsExisting}} 找到匹配的声明",
            "import_user_groups_user_group_claims_updated_added": "{{claimsAdded}} 声明已添加",
            "import_user_groups_no_data": "无可导入的声明",
            "import_user_groups_user_group_no_longer_exists": "用户组 ‘{{userGroup}}’ 已不存在",
            "import_user_groups_user_group_not_exists_message": "为资源类型 ‘{{resourceType}}’ 内的资源 ‘{{resource}}’ 指定的用户组名 ‘{{userGroup}}’ 不存在。",
            "import_user_groups_unauthorised_user_group": "无操作：您没有更新该用户组的权限。",
            "import_user_groups_user_group_comma_name": "用户组名不得包含逗号 (,)。",
            "import_resource_missing_resource_type_name": "尚未指定资源类型名称。",
            "import_resource_missing_resource_name": "尚未指定资源名称",
            "import_resource_resource_type_does_not_exist": "资源类型 ‘{{resourceType}}’ 不存在",
            "import_resource_invalid_custom_field_email_value": "资源类型“{{resourceType}}”下资源“{{resource}}”的自定义字段“{{fieldName}}”：值“{{fieldValue}}”不是有效的电子邮件地址。",
            "import_resource_user_no_access_to_resource_type": "您没有更新资源类型的权限",
            "import_resource_invalid_permission_type": "资源类型 ‘{{resourceType}}’ 下的资源 ‘{{resource}}’被指定了无效的 ‘{{permissionType}}’ 权限类型",
            "import_resource_empty_permission_type": "没有为资源类型 ‘{{resourceType}}’ 下的资源 ‘{{resource}}’ 指定权限类型。",
            "import_resource_invalid_remove_and_add_permissions": "为资源类型 ‘{{resourceType}}’ 下的资源 ‘{{resource}}’ 指定的权限无效。无法为移除和添加权限提供条目。",
            "import_resource_invalid_non_empty_user_group": "为资源类型 ‘{{resourceType}}’ 下的资源 ‘{{resource}}’ 指定的用户组名 ‘{{userGroup}}’ 无效，其中无指定的权限类型。",
            "import_resource_resource_no_action": "无操作：资源 '{{resource}}' 不存在",
            "import_resource_create_resource_unauthorised": "您没有在资源类型 ‘{{resourceType}}’ 下创建资源 ‘{{resource}}’ 的权限",
            "import_resource_update_resource_unauthorised": "您没有在资源类型 ‘{{resourceType}}’ 下更新资源 ‘{{resource}}’ 的权限",
            "import_resource_invalid_user_group": "没有为资源类型 ‘{{resourceType}}’ 下的资源 ‘{{resource}}’ 指定用户组名。",
            "import_resource_invalid_user_group_parameter": "对于资源类型 ‘{{resourceTypeName}}’ 内的 {{resourceName}}，应只为其非请求权限指定一个参数。",
            "import_resource_user_group_not_exists": "用户组 ‘{{userGroup}}’ 不存在",
            "import_resource_resource_has_no_valid_user_groups": "无操作：资源无需要处理的有效用户组",
            "import_resource_resource_created": "将创建资源",
            "import_resource_resource_created_permission_types": "{{permissionsCreated}} ‘{{permissionType}}’ 权限已添加",
            "import_resource_resource_created_no_permissions": "资源将以0权限的方式创建。",
            "import_resource_resource_user_groups_replaced_deleted": "{{permissionsDeleted}} 现有权限已删除",
            "import_resource_resource_user_groups_replaced_no_action_deleted": "无操作：0权限已删除。",
            "import_resource_resource_user_groups_updated_existing": "无操作： {{permissionsExisting}} 与通过 {{permissionType}} 权限查找到的现有用户组相匹配。",
            "import_resource_resource_user_groups_added": "{{permissionsExisting}} '{{permissionType}}' 权限已添加",
            "import_resource_resource_user_groups_updated": "{{permissionsExisting}} '{{permissionType}}' 权限已更新",
            "import_resource_resource_user_groups_updated_added": "{{permissionsExisting}} '{{permissionType}}' 权限已添加",
            "import_resource_resource_user_groups_purged": "{{permissionsDeleted}} {{permissionType}} 权限将被删除。",
            "import_resource_resource_no_longer_exists": "资源已不存在",
            "import_resource_invalid_non_empty_claim_type": "为资源类型“{{resourceType}}”下的资源“{{resource}}”指定的声明类型“{{claimType}}”无效，其中无指定的权限类型。",
            "import_resource_invalid_non_empty_claim_value": "为资源类型“{{resourceType}}”下的资源“{{resource}}”指定的声明值“{{claimValue}}”无效，其中无指定的权限类型。",
            "import_resource_invalid_claim_type": "为资源类型“{{resourceType}}”下的资源“{{resource}}”指定的声明类型“{{claimType}}”无效，声明值为“{{claimValue}}”。",
            "import_resource_invalid_parameter": "对于资源类型“{{resourceType}}”下的资源“{{resource}}”，其参数必须设置为用户组或声明类型/值对。",
            "import_resource_invalid_claim_type_value_parameter": "对于资源类型“{{resourceType}}”下的资源“{{resource}}”，其参数“{{parameter}}”的“声明类型”和“声明值”为必填项。",
            "import_resource_invalid_request_parameter": "对于资源类型“{{resourceType}}”下的资源“{{resource}}”，其“请求”权限的参数“{{parameter}}”是必填项。",
            "Import_resource_invalid_non_empty_parameter2": "对于资源类型“{{resourceType}}”下的资源“{{resource}}”，其非“请求”权限不得使用参数 2 值。",
            "import_resource_invalid_custom_field_name": "无效的自定义字段：资源类型 ‘{{resourceType}}’ 内无自定义字段 ‘{{fieldName}}’",
            "import_resource_invalid_custom_field_value": "无效的自定义字段值：'{{fieldValue}}' 于自定义字段 ‘{{fieldName}}’ 为无效的字段值（资源类型 ‘{{resourceType}}’ 下的资源 ‘{{resource}}’）",
            "import_resource_resource_has_no_valid_custom_fields": "无操作：资源类型 ‘{{resourceType}}’ 下的资源 ‘{{resource}}’ 无有效的自定义字段需要处理",
            "import_resource_resource_created_custom_fields": "{{customFieldValues}} 已添加自定义字段值",
            "import_resource_resource_multi_selection_replace": "'{{fieldName}}'： {{currentChoicesCount}} 值已删除， {{newChoicesCount}} 值已添加",
            "import_resource_resource_multi_selection_update": "'{{fieldName}}'： {{newChoicesCount}} 值已添加",
            "import_resource_unspecified_mapping_resource_type": "无效映射：导入文件包含未在映射文件中指定的资源类型： '{{resourceType}}'",
            "import_resource_unspecified_mapping_field_names": "无效映射：导入文件包含未在映射文件中指定的字段名称： '{{fieldNames}}'",
            "import_resource_unspecified_mapping_field_names_for_resource_type": "无效映射：导入文件包含未在映射文件 “{{fieldNames}}” 中指定的应用于资源类型 ‘{{resourceType}}’ 的字段名称： '{{fieldNames}}'",
            "import_resource_multiple_mapping_field_names_for_resource_type": "无效映射：映射文件包含资源类型 '{{resourceType}}' 的重复字段条目： '{{fieldNames}}'",
            "import_resource_resource_custom_field_no_changes": "无操作： ‘{{fieldName}}’ 已经包含导入文件中的值",
            "import_resource_multiple_external_values_for_resource_type": "对于资源类型“{{resourceType}}”，导入文件中的单值字段包含多个外部值：{{fieldNames}}。",
            "import_resource_resource_custom_field_changed": "‘{{fieldName}}’ 已更新至 ‘{{fieldValue}}’",
            "import_resource_resource_multi_selection_no_update": "无操作： ‘{{fieldName}}’ 将添加0值",
            "import_data_exceeded_limit": "超出最大行数。试图导入{{dataRows}}行。导入行数限制为{{maxDataRowsAllowed}}行。"
          }
        },
        "resource_group_resources_manager": {
          "title": "在 {{name}} 中管理资源",
          "help": "单击下方的阴影区域以向该组添加资源。",
          "placeholder": "点击以选择资源"
        },
        "resource_type_new": {
          "title": "指定以下对象的主要信息：新资源类型"
        },
        "resource_type_edit": {
          "title": "更新以下对象的主要信息：{{name}}"
        },
        "resource_type_custom_fields_edit": {
          "title": "更新以下对象的特定字段：{{name}}",
          "error_field_empty": "一个自定义字段没有完成（无字段名称）。请添加名称或删除此字段并再试。",
          "error_field_duplicated": "部分自定义字段的名称相同。请确保字段名称互不重复。"
        },
        "resource_type_custom_fields_new": {
          "title": "创建以下对象的特定字段：新资源类型"
        },
        "resource_type_form": {
          "name": "资源类型的名称",
          "image_uri": "图片",
          "confirm_delete": "是否确定要删除此属性？这些属性仅在您单击保存按钮时才会保存。"
        },
        "resource_type_custom_fields_form": {
          "add_item": "添加项目",
          "add_item_instruction": "单击下方按钮来添加新属性"
        },
        "resource_restrictions_form": {
          "resource_type": "资源类型",
          "resource_group": "资源组：",
          "resource_groups": "资源组：",
          "resource_group_optional": "选择可选的资源组",
          "name": "名称：",
          "min": "最小：",
          "max": "最大：",
          "is_primary": "主要资源：",
          "add_item": "添加资源限制",
          "add_item_instruction": "单击下方按钮来添加新的资源限制"
        },
        "custom_field_item": {
          "save": "完成",
          "add": "添加项目",
          "is_filterable": "可按以下进行筛选",
          "name": "字段名称",
          "help_text": "帮助文本（可选）",
          "rank": "排名",
          "display_name": "显示名称",
          "value_type": "字段类型",
          "value_choices": "字段选择",
          "custom_field_not_supported": "类型“{{name}}”的自定义字段不受支持。",
          "restrict_visibility": "限制可见性",
          "user_group_placeholder": "选择一个用户组",
          "value_types": {
            "yesno": "是/否",
            "text": "文本",
            "number": "数字",
            "list": "列表",
            "discrete": "独立",
            "email": "电子邮件地址",
            "singleselection": "单项选择",
            "multipleselections": "多项选择"
          },
          "yesno": {
            "yes": "是",
            "no": "否"
          },
          "number": {
            "min": "最小：",
            "max": "最大："
          }
        },
        "resource_owner_list": {
          "owners": "该资源的所有者",
          "add_resource_owners": "选择一个用户组"
        },
        "resource_custom_fields_new": {
          "title": "定义以下对象的自定义字段：新资源"
        },
        "resource_custom_fields_edit": {
          "title": "定义以下对象的自定义字段：{{name}}"
        },
        "resource_form": {
          "description": "描述"
        },
        "resource_new": {
          "title": "指定新资源的主要信息",
          "name": "资源名称"
        },
        "resource_group_new": {
          "title": "指定新资源组的主要信息",
          "name": "组名称"
        },
        "resource_group_edit": {
          "title": "指定新资源组的主要信息",
          "name": "组名称"
        },
        "concurrency_form": {
          "description": "描述",
          "name": "名称",
          "enabled": "已启用",
          "include_resource_unavailability": "包含资源不可用性"
        },
        "concurrency_group_new": {
          "title": "指定新“同一时间”组的主要信息"
        },
        "concurrency_group_edit": {
          "title": "主要信息"
        },
        "concurrency_configuration_new": {
          "title": "指定新“同一时间”配置的主要信息"
        },
        "concurrency_configuration_edit": {
          "title": "主要信息"
        },
        "concurrency_configuration_groups_form": {
          "title": "更新以下对象的“同一时间”组：{{name}}",
          "search_placeholder": "搜索“同一时间”组",
          "members": "成员"
        },
        "concurrency_group_resources_form": {
          "title": "更新以下对象的资源：{{name}}",
          "search_placeholder": "搜索资源",
          "members": "成员"
        },
        "concurrency_resources_list": {
          "empty": "没有可显示的结果"
        },
        "resource_edit": {
          "title": "指定资源的主要信息",
          "name": "资源名称"
        },
        "availability_form": {
          "title": "定义以下对象的不可用时段：{{resource}}",
          "description": "使用<strong>“默认”</strong>日历可定义该资源在周中每天的不可用时间。只需在日历中单击即可添加、编辑或移除时间段。如需处理例外情况，使用<strong>“特定日期”</strong>日历可将该特定日子定义为不可用。",
          "define_unavailability": "定义以下对象的不可用性：",
          "defaults": "默认",
          "clear_unavailability": "清除不可用性",
          "clear_unavailability_title": "清除不可用性？",
          "undo_changes": "撤销所有更改",
          "clear_unavailability_message": "请选择想要清除的不可用性类型：",
          "undo_changes_message": "这样将撤消迄今对不可用性做的所有未保存的更改。是否确定？",
          "unavailability_type": "不可用性类型",
          "types": {
            "all_defaults": "所有默认不可用性",
            "all_specific": "所有特定不可用性",
            "future_specific": "未来（明天起）的特定不可用性"
          },
          "confirmation_messages": {
            "all_defaults": "是否确定要清除<b>所有默认/重复的不可用性</b>？此操作无法撤销。",
            "all_specific": "是否确定要清除<b>所有具体日期的不可用性</b>？此操作无法撤销。",
            "future_specific": "是否确定要清除<b>所有未来（明天起）</b>日期的不可用性？此操作无法撤销。"
          },
          "unavailability_clear_success": "不可用性已成功清除",
          "unavailability_clear_error": "无法清除不可用性。请稍后再试。",
          "unavailability_fetch_error": "此时无法检索不可用性。请稍后再试。",
          "specific_dates": "特定日期",
          "user_groups": {
            "any": "任何用户"
          }
        },
        "resource_settings": {
          "title": "以下对象的其他设置：{{resource}}",
          "resource_in_pool": "此资源池中共有多少资源？",
          "pool_note": "若非批量资源则留空",
          "time_before_after": "预定前后的必需时间",
          "minutes_before": "分钟早于",
          "minutes_after": "分钟晚于"
        },
        "rules_form": {
          "available_rules": "可用的规则",
          "add_item_instruction": "当前没有保存的规则",
          "search_user_group": "搜索用户组",
          "add_rule": "添加规则",
          "save": "保存",
          "cancel": "取消",
          "applies_to": "应用至",
          "exemption": "免除",
          "admins": "管理员",
          "resource_owners": "资源所有者",
          "booking_managers": "预定管理者",
          "approvers": "批准人",
          "user_groups": "用户组",
          "failure_message": "自定义信息",
          "confirm_delete": "是否确定要删除此规则？",
          "flash_messages": {
            "created": "规则已创建。",
            "deleted": "规则已删除。",
            "error_creating": "此时无法创建规则。请稍后再试",
            "error_deleting": "此时无法删除规则。请稍后再试",
            "error_saving": "无法保存规则。请检查高亮的字段。",
            "new_rule_validation_failed": "无法保存新规则。部分字段无效或为空。",
            "resource_restrictions_empty": "预定类型要求至少一个资源限制",
            "resource_restrictions_no_primary": "预定类型要求一个主要资源",
            "calendar_times_required": "日历选项的开始和结束时间是必填项",
            "resource_restrictions_invalid_max": "资源限制的“最大”值不得为“0”或小于“最小”值",
            "name_required": "需要命名该模板"
          },
          "action": "操作",
          "create": "预定",
          "create_and_update": "预定/更新",
          "update": "更新",
          "delete": "取消",
          "rules": {
            "6c8388ca-af45-4783-a17e-e431c9836d19": "最长预定时段",
            "b4fa85ea-9fe8-4a73-a10e-9429d38c2225": "最多预定数量",
            "c61583f9-ed46-4f38-a8a2-af9c33d409eb": "最多提前时间",
            "9ca638b0-ec37-4610-8100-fbdc072d681b": "最短提前天数",
            "c187ca70-c4a0-4e54-9b4b-8e7d95494bb2": "最多提前天数",
            "6e0ccce6-26e9-4418-b94b-b341e8df6837": "预定开放时段",
            "9af5dc64-aec5-4fcd-8fcb-0d4562793e8d": "预定关闭开始时段",
            "70573559-0d83-49c0-bbe6-be54f6520391": "最小时长",
            "773e7fa8-903f-4d1d-9eff-fa0a3daaf053": "最短提前时间",
            "7f85c172-8608-4b6d-a06f-af737ffecb0a": "预定关闭时段",
            "4e5f5c1e-7d93-41df-88be-e2cc05e924d4": "不能重复预定的时段（日期）"
          },
          "fields": {
            "number": "数目",
            "per": "每",
            "minutes": "分钟",
            "day": "天",
            "week": "周",
            "month": "月",
            "release_time": "释放时间",
            "release_time_hours_minutes": "释放时间",
            "release_time_placeholder": "选择时间",
            "date": "日期",
            "start_date": "开始日期",
            "end_date": "结束日期",
            "days": "天",
            "validate": "验证",
            "startofbooking": "预定开始",
            "endofbooking": "预定结束",
            "bookings_allowed": "允许的预定",
            "exclude_weekends": "不含周末"
          }
        },
        "task_modal": {
          "title": "请耐心等待……",
          "message": "请稍候。这可能需要一段时间，具体取决于操作。"
        },
        "user_lookup": {
          "search_types_placeholder": "选择声明类型",
          "query_placeholder": "搜索用户",
          "search_results": "{{claimType}} 的结果：{{claimValue}}",
          "clear_search": "清除搜索",
          "include_everyone_groups": "包含“所有人”用户组",
          "bulk_clear": "批量清除权限",
          "bulk_clear_title": "批量清除",
          "bulk_clear_message": "这样将移除分配给以下对象的所有权限：<br><br><strong>{{key}}：{{value}}</strong>",
          "fetch_error": "此时无法提取权限摘要。请稍后再试。",
          "progress_failed_title": "完成操作时出错",
          "progress_failed_message": "无法完成当前任务。请稍后再试。",
          "task_complete_title": "任务已完成",
          "task_complete_message_no_results": "未找到以下对象的权限：<br><br><strong>{{key}}：{{value}}</strong>",
          "task_complete_message_incomplete": "以下对象的 {{successfulResults}}/{{totalResults}} 个权限已被移除：<br><br><strong>{{key}}：{{value}}</strong>",
          "task_complete_message_all": "以下对象的所有 {{successfulResults}} 个权限已被移除：<br><br><strong>{{key}}：{{value}}</strong>",
          "progress_messages": {
            "removepermissions_fetchpermissions": "正在查找用户权限...",
            "removepermissions_removeobjectpermissions": "正在移除权限..."
          }
        }
      },
      "system": {
        "system_settings": {
          "title": "系统设置",
          "subtitle": "编辑系统相关设置",
          "mode": "系统模式",
          "login_message": "登录信息",
          "login_message_description": "下方的登录信息内容会显示在登录页面上的“欢迎使用 Resource Booker”和登录按钮之间。",
          "enabled": "已启用",
          "modes": {
            "open_mode": "开放模式",
            "admin_only_mode": "仅管理员模式",
            "closed_mode": "封闭模式",
            "open_mode_short": "开放",
            "admin_only_mode_short": "仅管理员",
            "closed_mode_short": "封闭"
          },
          "open_mode_description": "默认无限制模式 - 这是 Resource Booker 的常规操作。",
          "admin_only_mode_description": "管理员用户可看到无限制的系统操作，但普通用户只能看见“我的预定”。他们无权访问“预定”或“通知”，也无法创建/取消预定。",
          "closed_mode_description": "所有用户都可看到“我的预定”，他们无权访问“预定”或“通知”，也无法创建/取消预定。管理员用户仍会看到自己的管理菜单项目。",
          "save": "保存"
        }
      },
      "reports": {
        "audit": {
          "audit_options": "选项",
          "report_everything": "所有",
          "report_custom_selection": "自定义选择",
          "report_selection": "报告选择",
          "date_format": "YYYY MMM D",
          "date_format_created": "YYYY MMM D HH:mm",
          "description": "生成预定操作的新报告",
          "title": "审计",
          "from_placeholder": "选择开始日期",
          "to_placeholder": "选择结束日期",
          "generate": "生成",
          "from_date_cannot_be_after_to_date": "开始日期不得晚于结束日期",
          "from_date_cannot_be_in_future": "开始日期不得是未来日期",
          "created": "已创建",
          "name": "名称",
          "requested_by": "请求人",
          "from_date": "从",
          "to_date": "至",
          "report_based_on": "报告基于：",
          "booking_dates": "预定日期",
          "action_dates": "操作日期",
          "date_range": "日期范围",
          "status": "状态",
          "actions": "操作",
          "download": "下载",
          "delete": "删除",
          "no_reports": "当前没有已生成的报告",
          "column_names": {
            "identity": "身份",
            "additionalEmailAddresses": "代表以下人士预定",
            "reference": "参考",
            "title": "标题",
            "description": "描述",
            "startTimeUtc": "开始时间（UTC）",
            "endTimeUtc": "结束时间（UTC）",
            "startTimeLocal": "开始时间（当地时间）",
            "endTimeLocal": "结束时间（当地时间）",
            "durationMinutes": "持续时间（分钟）",
            "bookerId": "预定人Id",
            "bookerName": "预定人姓名",
            "bookerEmailAddress": "预定人电子邮件",
            "resourceType": "资源类型",
            "resourceName": "资源名称",
            "templateName": "模板名称",
            "currentStatus": "当前状态",
            "statusAfterAction": "操作后状态",
            "action": "操作",
            "actionDateUtc": "操作日期（UTC）",
            "actionDateLocal": "操作日期（当地时间）",
            "actionedBy": "执行人",
            "notes": "提示",
            "form": "表单"
          }
        },
        "bookings": {
          "print_title": "预定报告",
          "no_results": "当前选择范围和时间段内没有预定。",
          "active_bookings": "筛选活动的预定",
          "export": "导出",
          "export_csv": "CSV"
        },
        "dashboard": {
          "title": "控制面板",
          "fetch_error": "无法加载报告，请稍后重试。",
          "no_reports": "当前没有已生成的报告",
          "select_report": "在下方列表中选择一个报告",
          "placeholder": "选择报告"
        },
        "flash_messages": {
          "generate_fail": "此时无法生成所请求的报告。请稍后再试。",
          "delete_confirm": "是否确定要删除此报告？此操作无法撤销。",
          "delete_fail": "此时无法删除该报告。请稍后再试。",
          "delete_success": "所选报告已删除。"
        }
      },
      "global_search_field": {
        "placeholder": "搜索",
        "booking_types": "模板",
        "resource_groups": "资源组",
        "resources": "资源",
        "more": "更多...",
        "no_results": "没有结果",
        "loading": "正在加载...",
        "resource_not_available": "当前没有允许您预定此资源的预定类型。"
      },
      "week_time_picker": {
        "hour_format": "HH:mm"
      },
      "datepicker": {
        "placeholder": "选择日期"
      }
    },
    "pods": {
      "logged_in": {
        "booking_request": {
          "index": {
            "subscribe": "订阅",
            "flash_messages": {
              "minimum_days_in_advance": "此操作不适用于提前申请天数少于 {{days}} 天的预定。",
              "unable_retrieve_booking": "无法检索预定信息",
              "maximum_days_in_advance": "您不得在 {{date}} 后取消预定，因为您已超出取消期限",
              "booking_window": "您只能在 {{startDate}} 至 {{endDate}} 期间取消预定",
              "booking_window_closed": "预订不得在 {{startDate}} 至 {{endDate}} 期间取消",
              "booking_window_multiple": "预定只能在以下日期范围内取消：",
              "booking_window_multiple_line": "{{startDate}} 至 {{endDate}}",
              "date_time_formats": {
                "booking_window": {
                  "startDate": "MMMM D",
                  "endDate": "MMMM D"
                },
                "booking_window_closed": {
                  "startDate": "MMMM D",
                  "endDate": "MMMM D"
                }
              }
            },
            "page_title": "我的预定",
            "my_bookings": "我的预定",
            "clear_search": "清除搜索",
            "refresh_bookings": "刷新",
            "cancel_booking_confirm_title": "确认取消",
            "cancel_booking_confirm": "是否确定要取消该预定？",
            "search_my_bookings": "搜索我的预定...",
            "cancel_booking_add_optional_note": "添加可选备注",
            "cancel_booking_confirm_single_occurrence": "是否确定要取消此单个事项？",
            "cancel_booking_confirm_all_occurrences": {
              "zero": "是否确定要取消该预定的所有事项？",
              "one": "是否确定要取消最后一个事项？",
              "other": "是否确定要取消该预定的所有 {{count}} 个事项？"
            },
            "print": "打印",
            "results_for": "下述对象的结果：",
            "searchLoading": "正在加载..."
          }
        },
        "confirm_logout": {
          "title": "注销",
          "message": "是否确定要注销？"
        },
        "notifications": {
          "empty_message": "您在选定时间段内没有此类通知。",
          "empty_message_approve": "您在选定时间段内没有要审批的预定。",
          "no_results": "当前的搜索条件未返回任何结果。请尝试移除部分筛选项或调整时间段。",
          "page_title": "通知",
          "title": "通知",
          "loading": "搜索通知..."
        },
        "resource": {
          "show": {
            "pick_required_resource": "选择必填的资源",
            "pick_optional_resource": "选择可选的资源",
            "invalid_booking_times_title": "预定类型已更改",
            "invalid_booking_times_message": "预定日期/时间已根据该预定类型的要求自动调整。请在更新预定前检查确认。",
            "bookings": "预定",
            "about_resource": "关于：{{resource}}",
            "more_infos": "{{resourceType}} 信息",
            "highlight_availability": "显示可用性",
            "description": "描述",
            "editing_booking": "正在编辑“{{name}}”",
            "cancel": "离开编辑模式",
            "no_properties": "未对该资源定义任何属性。",
            "check_availability": "检查其他资源的可用性",
            "availability_instructions": "请选择您希望预定的任何其他资源，以查看其可用性",
            "status": "状态",
            "main_resource": "该预定的主要资源",
            "required": "对该预定必需",
            "select_resource_type": "选择 {{resourceType}}",
            "other_available_resources": "对该预定可选",
            "event_click_in_edit_mode": "您无法在编辑模式下打开活动详情。请先离开编辑模式。",
            "error_resource_not_found": "未找到资源或者您无预定权限",
            "edit_with_different_booking_type_title": "编辑",
            "edit_with_different_booking_type_message": "此预订与不同的预订类型相关联。如需继续，您将被重定向至{{bookingType}}。"
          }
        },
        "resource_group": {
          "show": {
            "about_resource": "关于：{{resource}}",
            "more_infos": "更多信息",
            "description": "描述"
          }
        },
        "booking_type": {
          "index": {
            "bookings": "进行预定",
            "empty_message": "抱歉，现在没有可用于预定的资源。"
          },
          "show": {
            "bookings": "进行预定",
            "no_more_results": "没有更多结果",
            "show_more_results": "显示更多结果",
            "error_not_found": "未找到预定类型或者您无访问权限"
          }
        },
        "search_results": {
          "page_title": "搜索结果",
          "search": "搜索",
          "search_placeholder": "搜索",
          "booking_types": "模板",
          "resource_groups": "资源组",
          "resources": "资源",
          "no_results": "没有结果"
        },
        "user_profile": {
          "page_title": "用户资料",
          "saved": "您的更改已保存",
          "error_saving": "目前无法保存您的更改。请稍后再试。"
        }
      },
      "index": {
        "login": "登录",
        "welcome": "欢迎使用<br>Resource Booker"
      },
      "manage_resources": {
        "user_group": {
          "index": {
            "new_user_group": "新建用户组",
            "user_lookup": "用户查找",
            "page_title": "用户组",
            "user_groups": "用户组",
            "import": "导入/导出",
            "confirm_delete_message": "是否确定要删除此用户组？此操作无法撤销。",
            "confirm_delete_title": "确认删除",
            "empty_claim_error": "一个或多个 Claim 为空",
            "custom": "自定义",
            "roles": "角色",
            "pending_claim_error": "一个或多个声明仍处于编辑模式。请在保存前单击“完成”以确认所有更改。"
          },
          "new": {
            "disabled_tab_note": "* 保存该用户组时，禁用的选项卡会被启用",
            "page_title": "创建用户组",
            "title": "创建用户组",
            "description": "描述",
            "claims": "Claim",
            "permissions": "权限",
            "save": "保存"
          },
          "edit": {
            "delete_user_group": "删除用户组",
            "page_title": "编辑用户组",
            "title": "编辑用户组：{{name}}",
            "description": "描述",
            "claims": "Claim",
            "permissions": "权限",
            "save": "保存",
            "save_and_back": "保存并返回"
          },
          "importer": {
            "page_title": "导入/导出用户组",
            "import": "导入",
            "import_export": "导入/导出",
            "export": "导出",
            "upload": "上传",
            "preview": "预览",
            "summary": "摘要",
            "confirm_discard_changes_title": "未保存的更改",
            "confirm_discard_changes_message": "您有未保存的更改。是否确定要放弃更改并离开此页面？",
            "confirm_task_in_progress_title": "任务正在进行中",
            "confirm_task_in_progress_message": "导入正在进行中，离开此页面不会取消导入。你还想离开这个页面吗？",
            "bulk_importer": {
              "crumb_step1": "上传",
              "crumb_step2": "预览",
              "crumb_step3": "处理",
              "step1": {
                "preview": "预览",
                "export_template_title": "模板",
                "export_template_subtitle": "下载可用于导入的空模板",
                "export_import_file": "导入文件",
                "options": {
                  "or_drag_drop": "或拖放*",
                  "drag_support": "*并非所有浏览器都支持拖放",
                  "is_mapped_import": "包括映射文件",
                  "is_mapped_import_description": "导入是否包含将外部系统数据字段映射到Resource Booker自定义字段的文件？",
                  "import_file": "导入文件",
                  "import_file_description": "选择导入文件（CSV格式）",
                  "import_file_choose_file": "浏览",
                  "import_file_change_file": "更改文件",
                  "existing_user_groups": "现有用户组",
                  "existing_user_groups_description": "如果用户组已存在，请选择是要更新还是要替换其声明",
                  "update": "更新",
                  "replace": "替换",
                  "create_new_groups": "创建新群组",
                  "create_new_groups_description": "自动创建不存在的组？",
                  "has_headers": "有标头",
                  "has_headers_description": "您的CSV文件是否包含标头？"
                }
              },
              "step2": {
                "file_validation_success_title": "文件验证成功",
                "file_validation_success_description": "下方显示了更改后的预览。",
                "file_validation_fail_title": "文件验证失败",
                "file_validation_fail_description": "请检查并修复以下错误，然后重试。",
                "no_changes_title": "无变动",
                "no_changes_description": "流程没有变化。",
                "no_errors_found": "单击“确认”以应用这些更改，或单击“取消”返回上一页。"
              },
              "step3": {
                "total_results": "需要处理的项目总数：",
                "processed_results": "已处理的项目：",
                "successful_results": "成功的项目：",
                "ignored_results": "已忽略的项目：",
                "failed_results": "失败的项目："
              }
            },
            "bulk_exporter": {
              "title": "导出声明",
              "subtitle": "导出所有或选定用户组的声明",
              "export_all_title": "导出全部",
              "export_all_subtitle": "单击以导出所有用户组的声明",
              "export_all_button": "生成",
              "download_generated": "点击以下载",
              "download_not_generated": "下载未生成",
              "export_selected_title": "导出选择",
              "export_selected_subtitle": "在下方选择特定用户组并导出其声明",
              "export_selected_button": "生成",
              "placeholder": "点击以选择用户组"
            }
          }
        },
        "tab_no_permission": "此选项卡目前为只读状态。您没有“编辑”的权限。",
        "user_lookup": {
          "index": {
            "page_title": "用户权限摘要"
          }
        },
        "booking_type": {
          "index": {
            "create_booking_type": "新建模板",
            "page_title": "模板",
            "booking_types": "模板",
            "confirm_delete_message": "是否确定要删除此项目？此操作无法撤销。",
            "confirm_delete_title": "确认删除"
          },
          "edit": {
            "calendar": "日历",
            "notifications": "通知",
            "notifications_title": "更新以下对象的通知：{{name}}",
            "delete_booking_type": "删除模板",
            "page_title": "编辑模板",
            "title": "编辑模板：{{name}}",
            "save": "保存",
            "description": "描述",
            "status": "状态",
            "availability": "可用性",
            "rules": "规则",
            "resource_restrictions": "资源限制",
            "rules_title": "更新以下对象的规则：{{name}}",
            "resource_restrictions_title": "更新以下对象的资源限制：{{name}}",
            "resource_restrictions_edit_warning": "更改模板资源限制可能会影响用户对现有预定项的编辑权限",
            "permissions": "权限",
            "permissions_title": "定义以下对象的权限：{{name}}",
            "delete_restriction_title": "删除限制",
            "delete_restriction_message": "是否确定要删除此资源限制？"
          },
          "new": {
            "page_title": "创建模板",
            "title": "创建模板",
            "save": "保存",
            "description": "描述",
            "disabled_tab_note": "*保存该模板时，禁用的选项卡会被启用",
            "permissions": "权限",
            "permissions_title": "定义以下对象的权限：新模板"
          }
        },
        "booking_form": {
          "index": {
            "create_booking_form": "新建表单",
            "page_title": "表单",
            "forms": "表单",
            "confirm_delete_form_title": "删除表单？",
            "confirm_delete_form_message": "是否确定要删除此表单？此操作无法撤销。",
            "confirm_duplicate_form_title": "复制表单？",
            "confirm_duplicate_form_message": "是否确定要复制此表单？"
          },
          "edit": {
            "delete_form": "删除表单",
            "page_title": "编辑表单",
            "description": "描述",
            "fields": "字段",
            "confirm_delete_tab_title": "删除预定表单选项卡",
            "confirm_delete_tab_message": "是否确定要删除此选项卡及其所有关联字段？",
            "confirm_discard_changes_title": "未保存的更改",
            "confirm_discard_changes_message": "您有未保存的更改。是否确定要放弃更改并离开此页面？",
            "field_is_editing": "某个字段仍处于编辑模式。请单击“完成”结束编辑该字段，然后再保存表单。"
          },
          "new": {
            "page_title": "创建表单",
            "description": "描述",
            "main": "主要",
            "save": "保存",
            "disabled_tab_note": "*保存该表单时，禁用的选项卡会被启用",
            "errors": {
              "bookingform_nameinuse": "同名表单已存在．请使用其他名称"
            }
          }
        },
        "resource_type": {
          "index": {
            "new_resource": "新建资源",
            "new_resource_group": "新建组",
            "new_resource_type": "新建资源类型",
            "edit_resource_type": "编辑资源类型",
            "concurrency": "同一时间",
            "page_title": "资源类型",
            "resource_types": "资源类型",
            "import": "导入/导出",
            "create_resource_type": "创建新资源类型",
            "confirm_delete_message": "是否确定要删除此项目？此操作无法撤销。",
            "confirm_delete_title": "确认删除"
          },
          "flash_messages": {
            "bulk_delete_success": {
              "one": "该资源已成功删除。",
              "other": "{{count}} 个资源已成功删除。"
            },
            "bulk_delete_error": {
              "one": "该资源尚未删除。请稍后再试。",
              "other": "{{count}} 个资源尚未删除。请稍后再试。"
            },
            "bulk_delete_group_success": {
              "one": "该资源已成功删除",
              "other": "{{count}} 个资源已成功删除"
            },
            "bulk_delete_group_error": {
              "one": "该资源尚未删除。请稍后再试。",
              "other": "{{count}} 个资源组尚未删除。请稍后再试。"
            },
            "bulk_duplicate_success": {
              "one": "该资源已成功复制。",
              "other": "{{count}} 个资源已成功复制。"
            },
            "bulk_duplicate_error": {
              "one": "该资源尚未复制。请稍后再试。",
              "other": "{{count}} 个资源尚未复制。请稍后再试。"
            },
            "add_to_resource_group_success": {
              "one": "该资源已成功添加到该资源组。",
              "other": "{{count}} 个资源已成功添加到该资源组。"
            },
            "add_to_resource_group_error": {
              "one": "该资源尚未添加到该资源组。请稍后再试。",
              "other": "这些资源尚未添加到该资源组。请稍后再试。"
            }
          },
          "edit": {
            "view_resource_type": "查看资源类型",
            "delete_resource_type": "删除资源类型",
            "page_title": "编辑资源类型",
            "title": "编辑资源类型：{{name}}",
            "save": "保存",
            "description": "描述",
            "custom_fields": "自定义字段",
            "permissions": "权限",
            "permissions_title": "定义以下对象的权限：{{name}}"
          },
          "new": {
            "page_title": "创建资源类型",
            "title": "创建资源类型",
            "save": "保存",
            "description": "描述",
            "custom_fields": "自定义字段",
            "permissions": "权限",
            "permissions_title": "定义以下对象的权限：新资源类型"
          },
          "importer": {
            "index": {
              "page_title": "导入/导出",
              "resource_permissions": "资源权限",
              "custom_fields": "自定义字段"
            },
            "permissions": {
              "page_title": "导入/导出权限",
              "resource_permissions": "资源权限",
              "import": "导入",
              "export": "导出",
              "upload": "上传",
              "preview": "预览",
              "summary": "摘要",
              "confirm_discard_changes_title": "未保存的更改",
              "confirm_discard_changes_message": "您有未保存的更改。是否确定要放弃更改并离开此页面？",
              "confirm_task_in_progress_title": "任务正在进行中",
              "confirm_task_in_progress_message": "导入正在进行中，离开此页面不会取消导入。你还想离开这个页面吗？",
              "bulk_importer": {
                "crumb_step1": "上传",
                "crumb_step2": "预览",
                "crumb_step3": "处理",
                "step1": {
                  "preview": "预览",
                  "export_template_title": "模板",
                  "export_template_subtitle": "下载可用于导入的空模板",
                  "export_import_file": "导入文件",
                  "options": {
                    "import_file": "导入文件",
                    "import_file_description": "选择导入文件（CSV格式）",
                    "import_file_choose_file": "浏览",
                    "import_file_change_file": "更改文件",
                    "or_drag_drop": "或拖放*",
                    "drag_support": "*并非所有浏览器都支持拖放",
                    "existing_permissions": "现有权限",
                    "existing_permissions_description": "如果资源已存在，请选择是要更新还是要替换其权限",
                    "update": "更新",
                    "replace": "替换",
                    "create_new_resources": "创建新资源",
                    "create_new_resources_description": "自动创建不存在的资源？",
                    "has_headers": "有标头",
                    "has_headers_description": "您的CSV文件是否包含标头？"
                  }
                },
                "step2": {
                  "file_validation_success_title": "文件验证成功",
                  "file_validation_success_description": "下方显示了更改后的预览。",
                  "file_validation_fail_title": "文件验证失败",
                  "file_validation_fail_description": "请检查并修复以下错误，然后重试。",
                  "no_changes_title": "无变动",
                  "no_changes_description": "流程没有变化。",
                  "no_errors_found": "单击“确认”以应用这些更改，或单击“取消”返回上一页。"
                },
                "step3": {
                  "in_progress_title": "请耐心等待……",
                  "in_progress_description": "正在处理您的更改",
                  "import_finished_no_errors": "导入成功完成",
                  "import_finished_with_errors": "导入已完成但有错误",
                  "import_finished_with_errors_description": "导入已完成但有错误。请在下方查看",
                  "time_elapsed": "时间流逝：",
                  "total_results": "需要处理的项目总数：",
                  "processed_results": "已处理的项目：",
                  "successful_results": "成功的项目：",
                  "ignored_results": "已忽略的项目：",
                  "failed_results": "失败的项目："
                }
              },
              "bulk_exporter": {
                "title": "导出资源权限",
                "subtitle": "导出所有或选定资源类型的资源权限",
                "export_all_title": "导出全部",
                "export_all_subtitle": "单击以导出所有资源类型的资源权限",
                "export_all_button": "生成",
                "download_generated": "点击以下载",
                "download_not_generated": "下载未生成",
                "export_selected_title": "导出选择",
                "export_selected_subtitle": "在下方选择特定资源类型并导出其资源权限",
                "export_selected_button": "生成",
                "placeholder": "点击以选择资源类型"
              }
            },
            "custom_fields": {
              "page_title": "导入/导出自定义字段",
              "custom_fields": "自定义字段",
              "import": "导入",
              "export": "导出",
              "upload": "上传",
              "preview": "预览",
              "summary": "摘要",
              "confirm_discard_changes_title": "未保存的更改",
              "confirm_discard_changes_message": "您有未保存的更改。是否确定要放弃更改并离开此页面？",
              "confirm_task_in_progress_title": "任务正在进行中",
              "confirm_task_in_progress_message": "导入正在进行中，离开此页面不会取消导入。你还想离开这个页面吗？",
              "bulk_importer": {
                "crumb_step1": "上传",
                "crumb_step2": "预览",
                "crumb_step3": "处理",
                "step1": {
                  "preview": "预览",
                  "export_template_title": "模板",
                  "export_template_subtitle": "下载可用于导入的空模板",
                  "export_import_file": "导入文件（无映射需求）",
                  "export_import_file_with_mapping": "导入文件（与映射文件一起使用）",
                  "export_mapping_file": "映射文件",
                  "options": {
                    "or_drag_drop": "或拖放*",
                    "drag_support": "*并非所有浏览器都支持拖放",
                    "is_mapped_import": "包括映射文件",
                    "is_mapped_import_description": "您的导入是否包含映射文件？",
                    "import_file": "导入文件",
                    "import_file_description": "选择导入文件（CSV格式）",
                    "import_file_choose_file": "浏览",
                    "import_file_change_file": "更改文件",
                    "mapping_file": "映射文件",
                    "mapping_file_description": "选择一个文件，该文件将外部系统中的字段映射到已为资源类型定义的自定义字段",
                    "mapping_file_choose_file": "浏览",
                    "mapping_file_change_file": "更改文件",
                    "existing_multiple_selections": "现有的多项选择字段",
                    "existing_multiple_selections_description": "选择是要更新还是要替换多个选择字段的当前选定值",
                    "update": "更新",
                    "replace": "替换",
                    "create_new_resources": "创建新资源",
                    "create_new_resources_description": "自动创建不存在的资源？",
                    "has_headers": "有标头",
                    "has_headers_description": "您的CSV文件是否包含标头？"
                  }
                },
                "step2": {
                  "file_validation_success_title": "文件验证成功",
                  "file_validation_success_description": "下方显示了更改后的预览。",
                  "file_validation_fail_title": "文件验证失败",
                  "file_validation_fail_description": "请检查并修复以下错误，然后重试。",
                  "no_changes_title": "无变动",
                  "no_changes_description": "流程没有变化。",
                  "no_errors_found": "单击“确认”以应用这些更改，或单击“取消”返回上一页。"
                },
                "step3": {
                  "in_progress_title": "请耐心等待……",
                  "in_progress_description": "正在处理您的更改",
                  "import_finished_no_errors": "导入成功完成",
                  "import_finished_with_errors": "导入已完成但有错误",
                  "import_finished_with_errors_description": "导入已完成但有错误。请在下方查看",
                  "time_elapsed": "时间流逝：",
                  "total_results": "需要处理的项目总数：",
                  "processed_results": "已处理的项目：",
                  "successful_results": "成功的项目：",
                  "ignored_results": "已忽略的项目：",
                  "failed_results": "失败的项目："
                }
              },
              "bulk_exporter": {
                "title": "导出资源自定义字段",
                "subtitle": "导出所有或选定资源类型的自定义字段",
                "export_all_title": "导出全部",
                "export_all_subtitle": "单击以导出所有资源类型的资源自定义字段",
                "export_all_button": "生成",
                "download_generated": "点击以下载",
                "download_not_generated": "下载未生成",
                "export_selected_title": "导出选择",
                "export_selected_subtitle": "在下方选择特定资源类型并导出其资源自定义字段",
                "export_selected_button": "生成",
                "placeholder": "点击以选择资源类型"
              }
            }
          },
          "concurrency": {
            "tabs": {
              "main": "主要",
              "resources": "资源",
              "groups": "组"
            },
            "index": {
              "page_title": "管理同一时间",
              "concurrency_groups": "“同一时间”组",
              "concurrency_configurations": "“同一时间”配置",
              "configuration": "配置",
              "group": "分组",
              "create_concurrency_group": "创建组",
              "create_configuration_group": "创建配置",
              "search_config_or_group": "搜索“同一时间”配置或组",
              "confirm_delete_title": "确认删除？",
              "confirm_delete_message": "是否确定要删除“{{name}}”？此操作无法撤销。"
            },
            "show": {
              "page_title": "“同一时间”日历",
              "title": "查看日历 - {{name}}"
            },
            "configuration": {
              "new": {
                "page_title": "创建“同一时间”配置"
              },
              "edit": {
                "page_title": "编辑配置：{{name}}"
              }
            },
            "group": {
              "new": {
                "page_title": "创建“同一时间”组"
              },
              "edit": {
                "page_title": "编辑“同一时间”组：{{name}}"
              }
            }
          },
          "show": {
            "resource_types": "资源类型",
            "bulk_delete": "删除 ({{count}})",
            "bulk_duplicate": "复制 ({{count}})",
            "add_to_group": "添加到组",
            "confirm_bulk_delete": {
              "title": "删除确认",
              "message": {
                "one": "是否确定要删除此资源？此操作无法撤销。",
                "other": "是否确定要删除 {{count}} 个资源？此操作无法撤销。"
              }
            },
            "confirm_bulk_group_delete": {
              "title": "删除组确认",
              "message": {
                "one": "是否确定要删除此资源组？此操作只会移除该组，但不会移除相关资源。此操作也无法撤销。",
                "other": "是否确定要删除 {{count}} 个资源组？此操作只会移除这些组，但不会移除相关资源。此操作也无法撤销。"
              }
            },
            "confirm_bulk_duplicate": {
              "title": "复制确认",
              "message": {
                "one": "是否确定要复制此资源？此操作不会修改原始资源。",
                "other": "是否确定要复制 {{count}} 个资源？此操作不会修改原始资源。"
              }
            }
          }
        },
        "resource_group": {
          "flash_messages": {
            "remove_from_resource_group_success": {
              "one": "该资源已成功从该资源组中移除。",
              "other": "{{count}} 个资源已成功从该资源组中移除。"
            },
            "remove_from_resource_group_error": {
              "one": "该资源尚未从该资源组中移除。请稍后再试。",
              "other": "{{count}} 个资源尚未从该资源组中移除。请稍后再试。"
            }
          },
          "edit": {
            "view_resources": "查看资源",
            "delete_group": "删除组",
            "page_title": "编辑组",
            "resource_types": "资源类型",
            "title": "编辑组：{{name}}",
            "description": "描述",
            "availability": "可用性",
            "permissions": "权限",
            "resources": "资源",
            "revert_changes": "撤销更改",
            "permissions_title": "更新以下对象的权限：{{name}}",
            "settings": "设置"
          },
          "resources": {
            "delete_group": "删除组",
            "edit_group": "编辑组",
            "bulk_delete": "从组中移除 ({{count}})",
            "confirm_bulk_remove": {
              "title": "确认从组中移除",
              "message": {
                "one": "是否确定要从该组中移除此资源？此操作不会删除该资源。",
                "other": "是否确定要从该组中移除 {{count}} 个资源？此操作不会删除这些资源。"
              }
            }
          },
          "new": {
            "page_title": "创建组",
            "resource_types": "资源类型",
            "title": "创建组",
            "description": "描述",
            "save": "保存",
            "disabled_tab_note": "*保存该资源组时，禁用的选项卡会被启用",
            "permissions_title": "创建新组的权限"
          }
        },
        "resource": {
          "edit": {
            "view_calendar": "查看日历",
            "delete_resource": "删除资源",
            "page_title": "编辑资源",
            "resource_types": "资源类型",
            "title": "编辑资源：{{name}}",
            "description": "描述",
            "custom_fields": "自定义字段",
            "availability": "可用性",
            "permissions": "权限",
            "permissions_title": "更新以下对象的权限：{{name}}",
            "settings": "设置"
          },
          "show": {
            "page_title": "查看日历",
            "title": "查看日历 - {{name}}",
            "show_template_unavailability": "显示模板不可用性",
            "show_concurrency_unavailability": "显示“同一时间”活动不可用性",
            "view_mode": "查看模式",
            "modes": {
              "classic": "经典",
              "compact": "紧凑",
              "contrast": "对比"
            },
            "instructions": "查看谁预定了该资源，单击预定项可将其取消"
          },
          "new": {
            "page_title": "创建资源",
            "resource_types": "资源类型",
            "title": "创建资源",
            "description": "描述",
            "custom_fields": "自定义字段",
            "permissions_title": "创建新资源的权限",
            "save": "保存",
            "disabled_tab_note": "*保存该资源时，禁用的选项卡会被启用"
          }
        },
        "system": {
          "calendar": "日历",
          "calendar_options_saved": "日历选项已成功保存。",
          "page_title": "系统",
          "settings": "设置",
          "notifications_templates": "通知模板",
          "options_saved": "系统选项已成功保存。",
          "options_not_saved": "此时无法保存更改。请稍后再试。"
        },
        "reports": {
          "bookings": "预定",
          "page_title": "报告",
          "dashboard": "控制面板",
          "audit": "审计"
        }
      }
    }
  };
});
define('scientia-resourcebooker/serializers/booking-type', ['exports', 'ember', 'ember-data', 'scientia-resourcebooker/serializers/application'], function (exports, _ember, _emberData, _scientiaResourcebookerSerializersApplication) {

  // List of EditPermissionGroups on the bookingType object
  var EDIT_PERMISSION_GROUPS = ['all', 'status', 'availability', 'resourceRestrictions', 'permissions', 'rules', 'notifications', 'calendar'];

  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    systemSettings: _ember['default'].inject.service(),

    attrs: {
      resourceRestrictionRules: { embedded: 'always' },
      bookingForm: { embedded: 'always' }
    },

    normalize: function normalize(type, payload) {
      var applicationSerializer = this.store.serializerFor('application');

      payload = this.store.serializerFor('application')._camelizeKeysDeep(payload);
      payload = applicationSerializer._setPermissionGroupDefaults(payload);
      payload = applicationSerializer._normalizeNestedPermissionGroups(payload, EDIT_PERMISSION_GROUPS, 'editPermissionsGroups', 'edit%@PermissionGroups');

      payload = this._setCalendarOptionsDefaults(payload);

      // Default template mode in case template has not been set one yet (for backwards compat)
      if (!payload.Mode && !payload.mode) payload.Mode = '0';

      // Default Notification types set to "Banner"
      if (payload.infoType !== 0 && payload.infoType !== 1) payload.infoType = 0;
      if (payload.alertType !== 0 && payload.alertType !== 1) payload.alertType = 0;
      return this._super(type, payload);
    },

    serialize: function serialize() /*snapshot*/{
      var serialized = this._super.apply(this, arguments);
      var applicationSerializer = this.store.serializerFor('application');
      serialized = applicationSerializer._serializeNestedPermissionGroups(serialized, EDIT_PERMISSION_GROUPS, 'editPermissionsGroups', 'edit%@PermissionGroups');
      return serialized;
    },

    /**
     * Sets the default values of bookingType.calendarOptions. If calendarOptions has a default GUID then
     * we just use the global/system calendar options instead
     * @param {*} payload
     */
    _setCalendarOptionsDefaults: function _setCalendarOptionsDefaults(payload) {
      var payloadCalendarOptions = payload.calendarOptions;
      if (!payloadCalendarOptions || payloadCalendarOptions.identity === '00000000-0000-0000-0000-000000000000') {
        payload.calendarOptions = this.get('systemSettings').getCalendarOptions();
      }

      if (payload.calendarOptions.calendarEndTime === '00:00') payloadCalendarOptions.calendarEndTime = '24:00';

      return payload;
    }

  });
});